import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from "@/locales/en"
import fr from "@/locales/fr"

Vue.use(VueI18n)
var locales = ['en', 'fr']
var locale = 'en';

if (locales.includes(localStorage.default_locale)) {
  locale = localStorage.default_locale;
} else {
  let browserLocal = navigator.language || navigator.userLanguage;

  if (browserLocal.includes('fr'))
    locale = 'fr';
  else
    locale = 'en';

  localStorage.default_locale = locale;
}

export default new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: { en: en, fr: fr },
})

export const availablesLocales = locales;
