import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { concat } from "apollo-link";

Vue.use(VueApollo);

const globalLink = setContext((_, { heads }) => {
  var headers = { ...heads };

  if (localStorage.default_locale)
    headers["CURRENT_LOCALE"] = localStorage.default_locale;

  if (localStorage.accessToken)
    headers["Authorization"] = `Bearer ${localStorage.accessToken}`;

  return { headers: headers };
});

const graphqlHttpLink = createHttpLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
  credentials: "include"
});

function createClient() {
  return new ApolloClient({
    link: concat(globalLink, graphqlHttpLink),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache"
      }
    }
  });
}

function createProvider(client) {
  const apolloProvider = new VueApollo({
    defaultClient: client,
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log("%cError", "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;", error.message);
    }
  });

  return apolloProvider;
}

export const apolloClient = createClient();
export const apolloProvider = createProvider(apolloClient);
