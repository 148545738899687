<template>
  <v-dialog content-class="rounded-lg" v-model="dialog" width="540">
    <v-card class="rounded-lg">
      <v-toolbar flat dense color="error" dark>
        <v-icon size="20">mdi-shield-alert</v-icon>

        <v-toolbar-title class="pl-2 subtitle-1">
          {{ destroyTitle ? destroyTitle : this.$t("misc.delete") }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text
        class="pa-4 body-2"
        v-if="!destroyLoading"
        v-html="
          destroyMessage ? destroyMessage : this.$t('misc.deleteAreYouSure')
        "
      />

      <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
        <v-row wrap no-gutters>
          <v-col class="text-xs-center d-flex justify-center" cols="12"
            ><v-progress-circular color="error" indeterminate size="48" width="4"></v-progress-circular></v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              {{ $t("misc.deleting") }}
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="destroyLoading" small depressed @click="close()">
          {{ $t("misc.no") }}
        </v-btn>
        <v-btn
          class="white--text"
          :disabled="destroyLoading"
          small
          color="error"
          depressed
          @click="destroy()"
        >
          <v-icon small class="pr-2">mdi-shield-alert</v-icon
          >{{ $t("misc.yes") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "destroyDialog",
  mixins: [],
  components: {},
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "destroyDialogOpened",
      "destroyDialogClosed",
      "destroyLoading",
      "destroyMessage",
      "destroyTitle",
    ]),
  },
  methods: {
    ...mapActions(["destroyResource"]),
    close() {
      this.dialog = false;
    },
    destroy() {
      this.destroyResource().then((data) => {
        this.dialog = false;
      });
    },
  },
  watch: {
    destroyDialogOpened() {
      this.dialog = true;
    },
    destroyDialogClosed() {
      this.dialog = false;
    },
  },
};
</script>
