import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import agendaStore from '@/stores/agendaStore';
import appStore from '@/stores/appStore';
import destroyDialogStore from '@/stores/destroyDialogStore';
import meetingStore from '@/stores/meetingStore';
import organisationStore from '@/stores/organisationStore';
import cardStore from '@/stores/cardStore';
import objectStore from '@/stores/objectStore';

const store = new Vuex.Store({
  strict: process.env.VUE_APP_ENV === 'development',
  modules: {
    agendaStore,
    appStore,
    cardStore,
    destroyDialogStore,
    meetingStore,
    organisationStore,
    objectStore
  }
});

export default store;
