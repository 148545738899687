import * as api from '@/services/no-meetings.api'
import { connectToChannel, disconnectFromChannel } from '@/services/action-cable.api';
import router from '@/plugins/router'
const state = {
  meetingId: '',
  meeting: {
    topics: [],
    objectives: [],
    duration: 0,
    starts_at: "",
    started_at: "",
    timed_topics: false
  },
  loadingMeeting: true,
  meetingFetchFailed: false,
  selectedTopicId: '',
  objectivesRefresh: 0,
  assetRefresh: 0,
  topicsRefresh: 0
}

const getters = {
  meetingId: state => state.meetingId,
  meeting: state => state.meeting,
  loadingMeeting: state => state.loadingMeeting,
  meetingFetchFailed: state => state.meetingFetchFailed,
  selectedTopicId: state => state.selectedTopicId,
  selectedTopic: state => {
    if (!state.meeting || !state.meeting.topics.length > 0)
      return {};
    return state.meeting.topics.find(e => e.id == state.selectedTopicId)
  },
  selectedTopicIndex: state => state.meeting.topics.findIndex(e => e.id === state.selectedTopicId),
  currentTopicIndex: state => state.meeting.topics.findIndex(e => e.id === state.meeting.current_topic_id),
  objectives: state => state.meeting.objectives,
  objectivesRefresh: state => state.objectivesRefresh,
  assetRefresh: state => state.assetRefresh,
  topicsRefresh: state => state.topicsRefresh,
  meetingUsers: state => state.meeting.meeting_users.map(e => e.user)
}

const actions = {
  mergeAssetsToFollowUp({ commit }, { topic_id, assets }) {
    commit("mergeAssetsToFollowUp", { topic_id, assets })
    console.log('mergeAssetsToFollowUp '+ topic_id + ' ' + assets);
  },
  setSelectedTopicId({ commit }, id) {
    commit("setSelectedTopicId", id);
  },
  async connectToMeetingChannel({ commit, dispatch }, id) {
    console.log("connectToMeetingChannel");
    connectToChannel("MeetingChannel", id, dispatch);
  },
  async disconnectFromMeetingChannel({ commit, dispatch }, id) {
    console.log("disconnectFromMeetingChannel");
    disconnectFromChannel(id);
  },
  async connectToFollowUpChannel({ commit, dispatch }, id) {
    console.log("connectToFollowUpChannel");
    connectToChannel("FollowUpChannel", id, dispatch)
  },
  async disconnectFromFollowUpChannel({ commit, dispatch }, id) {
    console.log("disconnectFromFollowUpChannel");
    disconnectFromChannel(id);
  },
  updateMeeting({ commit, dispatch }, { meeting }) {
    console.log("updateMeeting : " + meeting.status);
    commit("updateMeeting", meeting);
  },
  async getMeeting({ commit, dispatch }, id) {
    commit("setLoadingMeeting", true);
    commit("setSelectedTopicId", null);
    commit("meetingFetchFailed", false);
    commit("setMeetingId", id);
    try {
      let { data } = await api.getMeeting(id);
      commit("setMeeting", data.meeting);
      document.title = data.meeting.title;
      /* Change the selected organisation with the meeting organisation id */
      commit("setSelectedOrganisationId", data.meeting.organisation_id);
      data.meeting.topics.forEach(topic => {
        if (topic.follow_up && topic.follow_up.cards) {
          commit("addFollowUpCards", { follow_up_id: topic.follow_up_id, cards: topic.follow_up.cards });
        } else {
          /* The topic is associated to a follow up but the follow up is not visible for the user */
          topic.not_visible = true;
          commit("updateTopic", topic);
        }
        connectToChannel("FollowUpChannel", topic.follow_up_id, dispatch);
      })
      if (data.meeting.current_topic_id)
        commit("setSelectedTopicId", data.meeting.current_topic_id);
      commit("meetingFetchFailed", false);
    } catch (err) {
      console.error(err);
      commit("meetingFetchFailed", true);
    } finally {
      commit("setLoadingMeeting", false);
    }
  },
  addObjective({ commit }, { objective }) {
    commit("addObjective", objective);
  },
  updateObjective({ commit }, { objective }) {
    commit("updateObjective", objective);
  },
  removeObjective({ commit }, { objective }) {
    commit("removeObjective", objective);
  },
  addMeetingUser({ commit }, { meeting_user }) {
    commit("addMeetingUser", meeting_user);
  },
  removeMeetingUser({ commit }, { meeting_user }) {
    commit("removeMeetingUser", meeting_user);
  },
  addTopic({ commit, dispatch }, { topic }) {
    commit("addTopic", topic);
    commit("addFollowUpCards", { follow_up_id: topic.follow_up_id, cards: topic.follow_up.cards });
    connectToChannel("FollowUpChannel", topic.follow_up_id, dispatch);
  },
  updateTopic({ commit }, { topic }) {
    commit("updateTopic", topic);
  },
  removeTopic({ commit }, { topic }) {
    commit("removeTopic", topic);
  },
  setTopics({ commit }, { topics }) {
    commit("setTopics", topics);
  },
  setTopicsPositions({ commit }, { topics_positions }) {
    commit("setTopicsPositions", { topics_positions });
  }
}

const mutations = {
  setMeetingId(state, id) {
    state.meetingId = id;
  },
  setMeeting(state, meeting) {
    state.meeting = meeting;
  },
  updateMeeting(state, meeting) {
    if (state.meeting.id === meeting.id) {
      state.meeting.status = meeting.status;
      state.meeting.current_topic_id = meeting.current_topic_id;
      state.meeting.current_topic_elapsed_seconds = meeting.current_topic_elapsed_seconds;
      state.meeting.current_topic_elapsed_seconds_at = meeting.current_topic_elapsed_seconds_at;
      state.meeting.title = meeting.title;
      state.meeting.instructions = meeting.instructions;
      state.meeting.location = meeting.location;
      state.meeting.duration = meeting.duration;
      state.meeting.starts_at = meeting.starts_at;
      state.meeting.started_at = meeting.started_at;
      state.meeting.timed_topics = meeting.timed_topics;
      state.meeting.minute_sended_at = meeting.minute_sended_at;
    }
  },
  setLoadingMeeting(state, isLoading) {
    state.loadingMeeting = isLoading;
  },
  meetingFetchFailed(state, isFailure) {
    state.meetingFetchFailed = isFailure;
    if (isFailure)
      router.push({ name: "page_not_found" });
  },
  setSelectedTopicId(state, id) {
    if (state.selectedTopicId === id && state.meeting.status != "started")
      state.selectedTopicId = null;
    else
      state.selectedTopicId = id;
  },
  addObjective(state, objective) {
    if (objective.meeting_id != state.meetingId) return;

    let index = state.meeting.objectives.findIndex(e => e.id === objective.id);
    /* If objective has already been added return */
    if (index >= 0) return;

    state.meeting.objectives.push(objective);
  },
  removeObjective(state, objective) {
    if (objective.meeting_id != state.meetingId) return;

    let index = state.meeting.objectives.findIndex(e => e.id === objective.id);
    /* If objective has already been removed return */
    if (index < 0) return;

    state.meeting.objectives.splice(index, 1);
  },
  updateObjective(state, objective) {
    let index = state.meeting.objectives.findIndex(e => e.id === objective.id);

    if (index < 0) return;

    state.meeting.objectives[index] = objective;
    state.objectivesRefresh++;
  },
  addMeetingUser(state, meeting_user) {
    if (meeting_user.meeting_id != state.meetingId) return;

    let index = state.meeting.meeting_users.findIndex(e => e.id === meeting_user.id);
    /* If meeting_user has already been added return */
    if (index >= 0) return;

    state.meeting.meeting_users.push(meeting_user);
  },
  removeMeetingUser(state, meeting_user) {
    if (meeting_user.meeting_id != state.meetingId) return;

    let index = state.meeting.meeting_users.findIndex(e => e.id === meeting_user.id);
    /* If meeting_user has already been removed return */
    if (index < 0) return;

    state.meeting.meeting_users.splice(index, 1);
  },
  addTopic(state, topic) {
    if (topic.meeting_id != state.meetingId) return;

    let index = state.meeting.topics.findIndex(e => e.id === topic.id);
    /* If topic has already been added return */
    if (index >= 0) return;

    state.meeting.topics.push(topic);
    state.topicsRefresh++;
  },
  updateTopic(state, topic) {
    let index = state.meeting.topics.findIndex(e => e.id === topic.id);

    if (index < 0) return;

    state.meeting.topics[index] = topic;
    state.topicsRefresh++;
  },
  removeTopic(state, topic) {
    if (topic.meeting_id != state.meetingId) return;

    let index = state.meeting.topics.findIndex(e => e.id === topic.id);
    /* If topic has already been removed return */
    if (index < 0) return;

    state.meeting.topics.splice(index, 1);

    if (state.meeting.topics.length == 0) {
      state.selectedTopicId = null;
    }
    state.topicsRefresh++;
  },
  setTopics(state, topics) {
    let topic = topics[0];

    if (topic && topic.meeting_id != state.meetingId) return;

    state.meeting.topics = topics.sort((a, b) => a.position < b.position ? -1 : +1);
    state.topicsRefresh++;
  },
  setTopicsPositions(state, { topics_positions }) {
    topics_positions.forEach(tp => {
      let topic = state.meeting.topics.find(e => e.id === tp.id)
      topic.position = tp.position;
    })
    state.meeting.topics = state.meeting.topics.sort((a, b) => a.position < b.position ? -1 : +1);
    state.topicsRefresh++;
  },
  mergeAssetsToFollowUp(state, { topic_id, assets }) {
    let topic = state.meeting.topics.find(e => e.id === topic_id);

    if (!topic) return;

    assets.forEach(asset => {
      /* if we have a followup we push assets on followup */
      if (topic.follow_up && topic.follow_up.assets.indexOf(asset) === -1)
        topic.follow_up.assets.push(asset);
      /* otherwise we push assets on topic */
      else if (topic.assets.indexOf(asset) === -1)
        topic.assets.push(asset);
    })
    state.assetRefresh++;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
