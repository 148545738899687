import Bugsnag from '@bugsnag/js';

import * as api from '@/services/no-meetings.api'
import router from '@/plugins/router'
import { getInstance } from "@/plugins/auth";
import { availablesLocales } from '@/plugins/i18n';
import amplitude from 'amplitude-js';

const state = {
  current_user_id: '',
  locale: '',
  notification: {
    color: '',
    text: ''
  },
  online: true,
  publicPages: [
    "/login",
    "/signup",
    "/confirm_email",
    "/forgot_password",
    "/set_password"
  ],
  notLoggedInPage: [
    '/login',
    '/signup'
  ]
}

const getters = {
  current_user_id: state => state.current_user_id,
  locale: state => state.locale,
  notification: state => state.notification,
  online: state => state.online,
  publicPages: state => state.publicPages,
}

const actions = {
  setCurrentUserField({ commit }, locale) {
    commit("setCurrentUserField", locale);
  },
  setNotification({ commit }, notification) {
    commit("setNotification", notification);
  },
  setOnline({ commit }, isOnline) {
    commit("setOnline", isOnline);
  },
  sendSuccessMutation({ commit }, params) {
    commit("setNotification", {
      color: "success darken-1",
      icon: "mdi-checkbox-marked-circle",
      text: `The ${params.resource_name} has been successfully ${
        params.action
      }`
    });
  },
  sendSuccess({ commit }, text) {
    commit("setNotification", { color: "success", text: text, icon: "mdi-checkbox-marked-circle"  });
  },
  sendError({ commit }, error) {
    if (typeof error == 'object' && error.graphQLErrors && error.graphQLErrors.length > 0)
      error = error.graphQLErrors.map(e => e.message).join(', ')

    commit("setNotification", {
      color: "error",
      text: error,
      icon: "mdi-alert-octagon",
      timeout: 3000
    });
  },
  initialize({ commit, dispatch }) {
    window.addEventListener("online", () => dispatch("checkConnection"));
    window.addEventListener("offline", () => dispatch("checkConnection"));
    dispatch("checkConnection");
    // console.log('initialize')
  },
  checkConnection({ commit, dispatch }) {
    if (typeof navigator.onLine !== "undefined" && navigator.onLine === false) {
      /* If the browser have an offline property check it first */
      console.error("Browser is offline. Connection canceled");
      commit("onOffline");
      return;
    }
    let authService = getInstance();
    if (authService.isAuthenticated)
      dispatch("getCurrentUser");
  },
  async getCurrentUser({ commit, dispatch }) {
    try {
      const { data } = await api.getCurrentUser();

      if (data.current_user)
        await dispatch("onLoggedIn", {
          currentUser: data.current_user,
        });
      else
        await dispatch("onLoggedOut");

      await dispatch("onOnline");

    } catch (err) {
      console.error(err);
      dispatch("onOffline");
    }
  },
  async onOnline({ state, commit }) {
    commit("setOnline", true);
  },
  async onOffline({ state, commit }) {
    commit("setOnline", false);
  },
  async onLoggedIn({ commit, dispatch, getters }, { currentUser } ) {
    await commit("setCurrentUserField", currentUser);
    await dispatch("setOrganisations", currentUser.organisations);
    await commit("setOwnedOrganisations", currentUser.owned_organisations);
    //await commit("setOrganisationInvitations", currentUser.organisation_invitations);
    if (getters.organisations.length === 0 &&
        router.currentRoute.path !== '/set_organisation') {
      router.push("/set_organisation");
    }

    await commit("setInstance", {
      model: 'current_user',
      instance: currentUser
    })

    if (process.env.VUE_APP_BUGSNAG_API_KEY) {
      Bugsnag.setUser(
        currentUser.id,
        currentUser.email,
        getters.currentUser ? getters.currentUser.first_name : ''
      )
    }

    // Set amplitude user id
    amplitude.getInstance().setUserId(currentUser.id);
    dispatch("amplitudeUpdateUserProperties");
  },
  async onLoggedOut({ commit }) {
    localStorage.accessToken = null;

    await commit("setCurrentUserField", { id: '', locale: '' });
  },
  async logOut({ commit, state, dispatch }) {
    try {
      dispatch("onLoggedOut");
    } catch (err) {
      console.error(err);
    }
  },
  amplitudeUpdateUserProperties({ commit, dispatch, getters }) {
    var selectedOrganisation = getters.selectedOrganisation;
    if (selectedOrganisation) {
      amplitude.getInstance().setUserProperties({
        "Email": getters.currentUser.email,
        "First Name": getters.currentUser.first_name,
        "Last Name": getters.currentUser.last_name,
        "Organisation": selectedOrganisation ? selectedOrganisation.name : '',
        "Organisation ID": selectedOrganisation ? selectedOrganisation.id : '',
        "Job Title": getters.currentUser.job,
      });
    }
  }
}

const mutations = {
  setCurrentUserField(state, currentUser) {
    state.current_user_id = currentUser.id;
    state.locale = currentUser.locale;
    if (currentUser.locale &&
        currentUser.locale != localStorage.default_locale &&
        availablesLocales.includes(currentUser.locale))
      localStorage.default_locale = currentUser.locale;
  },
  setNotification(state, { color, text, icon }) {
    state.notification = {
      color: color,
      icon: icon ? icon : "mdi-information",
      text: text,
      timeout: 1800
    };
  },
  setOnline(state, isOnline) {
    state.online = isOnline;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
