import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "publicPages"
    ]),
  },
  methods: {
    ...mapActions([
      "sendError",
      "sendSuccess",
      "sendSuccessMutation",
      "setOnline",
      "initialize",
      "checkConnection",
      "getOrganisations",
    ]),
  }
};
