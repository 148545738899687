import * as api from '@/services/no-meetings.api';
import { connectToChannel } from '@/services/action-cable.api';


const state = {
  organisations: [],
  organisationInvitations: [],
  ownedOrganisations: [],
  selectedOrganisationId: localStorage.lastSelectedOrgaId
}

const getters = {
  organisations: state => state.organisations.map(e => e).sort((a, b) => a.name.localeCompare(b.name)),
  organisationInvitations: state => state.organisationInvitations,
  ownedOrganisations: state => state.ownedOrganisations,
  selectedOrganisationId: state => state.selectedOrganisationId,
  selectedOrganisation: state => state.organisations.find(e => e.id === state.selectedOrganisationId),
  selectedOrganisationOwnerId: (state, getters) => getters.selectedOrganisation.owner_id,
  noOrganisationLogo: (state, getters) => !getters.selectedOrganisation.picture_url
}

const actions = {
  setOwnedOrganisations({ commit }, ownedOrganisations) {
    commit("setOwnedOrganisations", ownedOrganisations);
  },
  setOrganisations({ commit, dispatch }, organisations) {
    organisations.forEach(organisation => {
      connectToChannel("OrganisationChannel", organisation.id, dispatch);
    });
    commit("setOrganisations", organisations);
  },
  setOrganisationInvitations({ commit }, organisationInvitations) {
    commit("setOrganisationInvitations", organisationInvitations);
  },
  setSelectedOrganisationId({ commit, dispatch }, organisationId) {
    commit("setSelectedOrganisationId", organisationId);
    dispatch("amplitudeUpdateUserProperties");
  },
  async getOrganisations({ commit }) {
    try {
      const { data } = await api.getCurrentUserOrganisations();
      await commit("setOwnedOrganisations", data.current_user.owned_organisations);
      await commit("setOrganisations", data.current_user.organisations);
      await commit("setOrganisationInvitations", data.current_user.organisation_invitations);
    } catch (err) {
      console.error(err);
    }
  },
}

const mutations = {
  setOrganisations(state, organisations) {
    state.organisations = organisations;
    if (state.organisations.length === 0) {
      state.selectedOrganisationId = null;
      return;
    }

    let orgaIds = organisations.map(e => e.id);
    if (!orgaIds.includes(localStorage.lastSelectedOrgaId)) {
      state.selectedOrganisationId = state.organisations[0].id;
      localStorage.lastSelectedOrgaId = state.selectedOrganisationId;
    }
  },
  setOwnedOrganisations(state, ownedOrganisations) {
    state.ownedOrganisations = ownedOrganisations;
  },
  setOrganisationInvitations(state, organisationInvitations) {
    state.organisationInvitations = organisationInvitations;
  },
  setSelectedOrganisationId(state, id) {
    state.selectedOrganisationId = id;
    localStorage.lastSelectedOrgaId = id;


  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
