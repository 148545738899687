//import * as moment from 'moment';
import moment from "moment-timezone";
import * as api from '@/services/no-meetings.api'
import { RRule, RRuleSet, rrulestr } from 'rrule'

const state = {
  start_date: moment().startOf('month'),
  end_date: moment().endOf('month'),
  meetings: [],
  meeting_loading: false,
  meetings_refresh: 0,
  editedMeeting: {}
}

const getters = {
  startDate: state => state.start_date,
  endDate: state => state.end_date,
  editedMeeting: state => state.editedMeeting,
  real_meetings: state => state.meetings,
  meetings_refresh: state => state.meetings_refresh,
  virtual_meetings: (state, getters) => {
    let ret = [];
    getters.organisation_users_refresh;
    getters.recurrent_meetings_refresh;
    getters.meetings_refresh;
    getters.organisation_recurrent_meetings.forEach(rec => {
      if (!rec.recurring || !getters.startDate || !getters.endDate) return;
      /* The interval to generate occurrences begin at the recurrent
      meeting creation date */

      let utc_start_date = new Date(moment(rec.starts_at).utc(true).startOf('day'));

      if (rec.recurring.includes('FREQ=DAILY') && moment(rec.starts_at) < getters.startDate) {
        utc_start_date = new Date(moment(rec.starts_at).utc(true).startOf('day').set({
          month: new Date(getters.startDate).getMonth(),
          year: new Date(getters.startDate).getFullYear(),
        }));
      }

      let utc_end_date = new Date(moment(getters.endDate).utc(true))
      if (rec.ends_at) {
        let rec_ends_at = new Date(moment(rec.ends_at).utc(true).startOf('day'));
        if (utc_end_date > rec_ends_at)
          utc_end_date = rec_ends_at;
      }

      let parsed = RRule.parseString(rec.recurring);
      parsed.dtstart = utc_start_date;
      parsed.until = parsed.until ? parsed.until : utc_end_date;
      let rrule = new RRule(parsed);
      let occurrences = rrule.all();
      /*
        Remove occurrences if a real meeting associated to this
        recurrent meeting exists the same day
      */
      getters.real_meetings.filter(e => e.recurrent_meeting_id === rec.id).forEach(m => {
        let to_remove = new Date(moment(m.recurrent_meeting_starts_at).utc(true).startOf('day')).toUTCString();
        occurrences = occurrences.filter(o => {
          return new Date(moment(o).utc(true).startOf('day')).toUTCString() != to_remove
        });
      })
      /*
        Remove occurrences if an exception exists the same day
      */
      JSON.parse(rec.canceled_on_days || '[]').forEach(day => {
        occurrences = occurrences.filter(o => {
          let op = moment(o).utc(true).startOf('day').unix();
          let ex = moment(day).utc(true).startOf('day').unix();
          return op !== ex;
        });
      })

      ret = ret.concat(occurrences.map(e => {
        let rec_start = new Date(moment(rec.starts_at))

        let hour = rec_start.getHours();
        let minutes = rec_start.getMinutes();
        let startDate = new Date(moment(e).set({ hour: hour, minutes: minutes }));
        return {
          id: `virtual_${e}`,
          title: rec.title,
          duration: rec.duration,
          starts_at: moment(startDate.toISOString()).format('YYYY-MM-DD HH:mm:ss'),
          started_at: null,
          created_at: null,
          status: 'pending',
          user_ids: rec.user_ids,
          user_id: rec.user_id,
          color: rec.color,
          organisation_id: rec.organisation_id,
          recurrent_meeting_id: rec.id,
          recurrent_meeting: {
            recurrent_meeting_root_id: rec.recurrent_meeting_root_id,
            id: rec.id,
            recurring: rec.recurring,
            ends_at: rec.ends_at
          },
          virtual: true
        }
      }));
    })
    return ret;
  },
  meetings: (state, getters) => {
    return getters.real_meetings.concat(getters.virtual_meetings);
  }
}

const actions = {
  setStartDate({ commit }, date) {
    commit("setStartDate", date);
  },
  setEndDate({ commit }, date) {
    commit("setEndDate", date);
  },
  setEditedMeeting({ commit }, meeting) {
    commit("setEditedMeeting", meeting);
  },
  setMeeting({ commit }, meeting) {
    commit("setMeeting", meeting);
  },
  async getMeetings({ commit, dispatch }, filter) {
    try {
      commit("setMeetingLoading", true);
      const { data } = await api.getMeetings(filter);
      if (data.meetings)
        commit("setMeetings", data.meetings);
    } catch (err) {
      console.error(err);
      dispatch("onOffline");
    } finally {
      commit("setMeetingLoading", false);
    }
  },
  removeMeting({ commit }, meeting_id) {
    commit("removeMeeting", meeting_id);
  }
}

const mutations = {
  setStartDate(state, date) {
    state.start_date = date;
  },
  setEndDate(state, date) {
    state.end_date = date;
  },
  setMeetings(state, meetings) {
    state.meetings = meetings;
    state.meetings_refresh++;
  },
  setMeeting(state, meeting) {
    const index = state.meetings.findIndex(e => e.id === meeting.id);
    if (index >= 0)
      state.meetings[index] = meeting;
    else
      state.meetings.push(meeting);
    state.meetings_refresh++;
  },
  removeMeting(state, meeting_id) {
    state.meetings = state.meetings.filter(e => e != meeting_id);
  },
  setMeetingLoading(state, loading) {
    state.meeting_loading = loading;
  },
  setEditedMeeting(state, meeting) {
    state.editedMeeting = meeting;
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
