
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()]
  });
}

const bugsnag = Bugsnag.getPlugin('vue');

export default bugsnag;
