<template>
  <v-navigation-drawer
    app
    :permanent="showMenu"
    color="timeline_blue"
    :mini-variant="miniVariant"
    :value="showMenu"
    width="240"
    stateless
    class="main_navigation_menu"
  >
    <template v-slot:prepend>

      <!-- Extend, collapse menu -->
      <v-row
        wrap
        no-gutters
        style="height: 72px; align-content: center"
        align="center"
        justify="center"
        class="px-4"
      >
        <router-link to="/"
          ><img
            v-if="!miniVariant"
            alt="GetCollective"
            src="/images/logo_menu_white_nav.png"
            width="140"
            height="21"
        /></router-link>

        <v-spacer></v-spacer>
        <v-btn dark icon @click="miniVariant = !miniVariant">
          <v-icon> {{ miniVariant ? "mdi-menu" : "mdi-backburger" }} </v-icon>
        </v-btn>
      </v-row>

      <!-- Main menu items -->
      <v-list dark dense class="px-0 white--text pb-4 pt-0">
        <v-list-item
          active-class="list_item_current_route"
          link
          :to="item.to"
          v-for="item in mainMenuItems"
          :key="item.id"
        >
          <v-list-item-icon class="mr-2 customIconMenu">
            <customIcon v-if="item.customIcon" color="#d2dbf7"
              ><component :is="item.customIcon"
            /></customIcon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="navigation-menu-border"></v-divider>
    </template>

    <!-- Secondary menu items -->
    <!-- <v-list dark dense class="px-0 white--text pt-4 pb-4">
      <v-list-item
        active-class="list_item_current_route"
        link
        :to="item.to"
        v-for="item in secondaryMenuItems"
        :key="item.id"
      >
        <v-list-item-icon class="mr-2 customIconMenu">
          <customIcon v-if="item.customIcon" color="#d2dbf7"
            ><component :is="item.customIcon"
          /></customIcon>
          <v-icon small color="#d2dbf7" v-if="item.icon">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->

    <!-- <v-divider class="navigation-menu-border"></v-divider> -->

    <template v-slot:append>
      <v-divider class="navigation-menu-border"></v-divider>

      <!-- Bottom menu items -->
      <v-list dark dense class="px-0 white--text pt-4 pb-4">
        <v-list-item
          active-class="list_item_current_route"
          link
          :to="item.to"
          :href="item.url"
          v-for="item in bottomMenuItems"
          :key="item.id"
          :target="item.target"
          @click="item.amplitudeEvent ? $amplitude.getInstance().logEvent(item.amplitudeEvent) : null"
        >
          <v-list-item-icon class="mr-2 customIconMenu">
            <customIcon v-if="item.customIcon" color="#d2dbf7"
              ><component :is="item.customIcon"
            /></customIcon>
            <v-icon small color="#d2dbf7" v-if="item.icon">{{
              item.icon
            }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>


<script>
import projectsNavigation from "../projects/projectsNavigation";
import { mapGetters } from "vuex";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import iconProject from "@/components/custom_icons/iconProject";
import iconMeeting from "@/components/custom_icons/iconMeeting";
import iconTemplate from "@/components/custom_icons/iconTemplate";
import iconRoadmap from "@/components/custom_icons/iconRoadmap";
import iconTasks from "@/components/custom_icons/iconTasks";

export default {
  name: "navigationMenu",
  mixins: [],
  components: {
    projectsNavigation,
    customIcon,
    iconFollowup,
    iconProject,
    iconMeeting,
    iconTemplate,
    iconRoadmap,
    iconTasks,
  },
  data() {
    return {
      miniVariant: false,
      forceMini: false,
      mainMenuItems: [
        {
          customIcon: "iconMeeting",
          icon: "",
          title: "navigationMenu.upcomingMeeting",
          to: "/",
          id: "meetings",
        },
        {
          customIcon: "iconFollowup",
          icon: "",
          title: "navigationMenu.followUps",
          to: "/followups",
          id: "followUps",
        },
        {
          customIcon: "iconTasks",
          icon: "",
          title: "navigationMenu.tasks",
          to: "/tasks",
          id: "tasks",
        },
        {
          customIcon: "iconRoadmap",
          icon: "",
          title: "projects.roadmap",
          to: "/projects",
          id: "roadmap",
        },
      ],
      secondaryMenuItems: [
        {
          customIcon: "iconTemplate",
          icon: "",
          title: "navigationMenu.templates",
          to: "/templates",
          id: "templates",
        },
      ],
    };
  },
  computed: {
    // miniVariant() {
    //   return this.$vuetify.breakpoint.mdAndDown || this.forceMini;
    // },
    webinarUrl() {
      if (this.$i18n.locale === "fr")
        return "https://us02web.zoom.us/webinar/register/WN_PnViVCAcQvuw1TCWlnzY9Q";
      else
        return "https://us02web.zoom.us/webinar/register/WN_cOj7kUkRS-ms46VG1OsWDQ";
    },
    helpUrl() {
      if (this.$i18n.locale === "fr")
        return "https://info.getcollective.com/contactez-notre-equipe-support";
      else return "https://info.getcollective.com/contact-our-support-team";
    },
    showMenu() {
      if (this.$route.name == "meeting_show") return false;
      return true;
    },
    bottomMenuItems() {
      return [
        {
          customIcon: "",
          icon: "mdi-home-circle",
          title: "navigationMenu.organisations",
          target: "",
          to: "/organisations",
          id: "organisations",
        },
        {
          customIcon: "",
          icon: "mdi-account-group",
          title: "navigationMenu.groups",
          target: "",
          to: "/groups",
          id: "groups",
        },
        {
          customIcon: "",
          icon: "mdi-cog",
          title: "navigationMenu.settings",
          target: "",
          to: "/settings",
          id: "settings",
        },
        {
          customIcon: "",
          icon: "mdi-message-video",
          title: "navigationMenu.webinar",
          to: '',
          url: this.webinarUrl,
          amplitudeEvent: "Click on 'Webinars'",
          target: "_blank",
          id: "templates",
        },
        {
          customIcon: "",
          icon: "mdi-help-circle",
          title: "navigationMenu.needHelp",
          to: '',
          url: this.helpUrl,
          amplitudeEvent: "Click on 'Need Help'",
          target: "_blank",
          id: "needHelp",
        },
      ];
    },
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    '$vuetify.breakpoint.mdAndDown' () {

      //Screen is large
      if (this.$vuetify.breakpoint.mdAndDown == false && this.forceMini == false){
        this.miniVariant = false;
      }

            //Screen is large
      if (this.$vuetify.breakpoint.mdAndDown == true && this.forceMini == false){
        this.miniVariant = true;
      }

    }
  }
};
</script>

<style scoped>
.list_item_current_route {
  background-color: var(--v-timeline_lighter_blue);
  color: white !important;
}

.theme--dark.v-list-item--active::before {
  opacity: 0;
}
</style>

<style>
.main_navigation_menu.v-navigation-drawer {
  transition-duration: 0ms !important;
}

.customIconMenu {
  align-items: center !important;
  justify-content: center;
}

.navigation-menu-border.v-divider {
  border-color: rgb(255, 255, 255, 0.15) !important;
}
</style>


