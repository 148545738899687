import Vue from 'vue'
import VueRouter from 'vue-router'
import { getInstance } from "@/plugins/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/followups',
    name: 'followups_index',
    component: () => import("@/components/followups/followups_index")
  },
  {
    path: '/followups/:id',
    name: 'followup_show',
    component: () => import("@/components/followups/followup_show")
  },
  {
    path: '/',
    name: 'meetings_index',
    component: () => import("@/components/meetings/meetings_index")
  },
  {
    path: '/meetings/new',
    name: 'new_meeting',
    component: () => import("@/components/meetings/new_meeting")
  },
  {
    path: '/meetings/:id',
    name: 'meeting_show',
    component: () => import("@/components/meetings/meeting_show")
  },
  {
    path: '/settings',
    name: 'user_settings',
    component: () => import("@/components/users/user_settings")
  },
  {
    path: '/set_organisation',
    name: 'set_organisation',
    component: () => import("@/components/organisations/set")
  },
  {
    path: '/confirm_email',
    name: 'confirm_email',
    component: () => import("@/components/public/confirm_email")
  },
  {
    path: '/organisations',
    name: 'organisations_index',
    component: () => import("@/components/organisations/organisations_index")
  },
  {
    path: '/templates',
    name: 'templates_index',
    component: () => import("@/components/templates/templates_index")
  },
  {
    path: '/groups',
    name: 'groups_index',
    component: () => import("@/components/groups/groups_index")
  },
  {
    path: '/projects',
    name: 'roadmap',
    component: () => import("@/components/projects/roadmap")
  },
  {
    path: '/tasks',
    name: 'tasks_index',
    component: () => import("@/components/tasks/tasks_index")
  },
  {
    path: '/translator',
    name: 'translator',
    component: () => import("@/components/translator/translator")
  },
  {
    path: '/recurring_meeting/:id',
    name: 'recurring_meeting_show',
    component: () => import("@/components/meetings/recurring_meeting_show")
  },
  {
    path: "/page_not_found",
    name: "page_not_found",
    component: () => import("@/components/404/page_not_found")
  },
  {
    path: '*',
    redirect: '/page_not_found',
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });

  return next();
});

export default router
