import * as api from '@/services/no-meetings.api'

const state = {
  dialogOpened: 0,
  dialogClosed: 0,
  destroyLoading: false,
  id: '',
  model: '',
  onDestroy: null,
  title: '',
  message: ''
}

const getters = {
  destroyDialogOpened: state => state.dialogOpened,
  destroyDialogClosed: state => state.dialogClosed,
  destroyLoading: state => state.destroyLoading,
  destroyMessage: state => state.message,
  destroyTitle: state => state.title,
}

const actions = {
  async destroyResourceWithDialog({ commit, dispatch }, { id, model, onDestroy }) {
    commit('setDestroyArgs', { id, model, title: '', message: '', onDestroy });
    commit('openDialog');
    await dispatch('destroyResource');
    commit('closeDialog');
  },
  openDestroyDialog({ commit }, { id, model, title, message, onDestroy }) {
    commit('setDestroyArgs', { id, model, title, message, onDestroy });
    commit('openDialog');
  },
  async destroyResource({ commit, dispatch }) {
    try {
      commit("setDestroyLoading", true);
      const { data } = await api.destroy(state.id, state.model);
      commit("setDestroyLoading", false);
      commit("callOnDestroy");
      return data;
    } catch (err) {
      dispatch("sendError", err);
      console.error(err);
      commit("setDestroyLoading", false);
    }
  }
}

const mutations = {
  openDialog(state) {
    state.dialogOpened++;
  },
  closeDialog(state) {
    state.dialogClosed++;
  },
  setDestroyArgs(state, { id, model, title, message, onDestroy }) {
    state.id = id;
    state.model = model;
    state.title = title;
    state.message = message;
    state.onDestroy = onDestroy;
  },
  setDestroyLoading(state, isLoading) {
    state.destroyLoading = isLoading;
  },
  callOnDestroy(state) {
    if (state.onDestroy)
      state.onDestroy(state.id);
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
