import { apolloClient } from '@/plugins/apollo'
import gql from 'graphql-tag';

import USER_ORGANISATIONS_INDEX from '@/graphql/users/organisations_index.gql'
import CURRENT_USER from '@/graphql/users/current_user.gql'
import MEETING_SHOW from '@/graphql/meetings/show.gql';
import MEETING_INDEX from '@/graphql/meetings/index.gql';
//import TOPIC_INDEX from '@/graphql/topics/index.gql';

export function getCurrentUser() {
  return apolloClient.query({
    query: CURRENT_USER
  });
}

export function getCurrentUserOrganisations() {
  return apolloClient.query({
    query: USER_ORGANISATIONS_INDEX
  });
}

export function getMeeting(id) {
  return apolloClient.query({
    query: MEETING_SHOW,
    variables: { id: id },
  });
}

export function getMeetings(filter) {
  return apolloClient.query({
    query: MEETING_INDEX,
    variables: { filter: filter },
    fetchPolicy: "network-only",
  });
}

//export function getTopics(filter) {
//  return apolloClient.query({
//    query: TOPIC_INDEX,
//    variables: { filter: filter, order_by: "meeting.starts_at DESC" }
//  });
//}

export function destroy(id, model) {
  return apolloClient.mutate({
    mutation: gql`mutation($id: String!) { destroy_${model}(id: $id) { id } }`,
    variables: { id: id }
  })
}
