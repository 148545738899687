import Vue                  from 'vue';
import FlagIcon             from 'vue-flag-icon';
import JsonViewer           from 'vue-json-viewer';
import * as VueSpinnersCss  from "vue-spinners-css";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import bugsnag              from './plugins/bugsnag';

import { apolloProvider } from './plugins/apollo';
import { Auth0Plugin }    from './plugins/auth';
import i18n               from './plugins/i18n';
import router             from './plugins/router';
import store              from './plugins/vuex';
import vuetify            from './plugins/vuetify';
import actions     from './mixins/actions';
import application from './mixins/application';
import Fragment from 'vue-fragment';

import App from './App.vue';

import amplitude from 'amplitude-js';

Object.defineProperty(Vue.prototype, '$amplitude', { value: amplitude });
amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY); // initializes default instance of Amplitude client

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  bugsnag.installVueErrorHandler(Vue);
}

// import schedulerFollowupStatusDropdown from '@/components/projects/schedulerFollowupStatusDropdown.vue';

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});


Vue.use(FlagIcon);
Vue.use(VueSpinnersCss);
Vue.use(Fragment.Plugin);

Vue.mixin(actions);
Vue.mixin(application);

if (process.env.VUE_APP_ENV === 'development'){
  Vue.component('jsonViewer', JsonViewer);
}
Vue.component('ctkDatePicker', VueCtkDateTimePicker);
// Vue.component('schedulerFollowupStatusDropdown', schedulerFollowupStatusDropdown);
new Vue({
  apolloProvider: apolloProvider,
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
