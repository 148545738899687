export default {
  organisations: {
    headerLineJoin: "Join an organization",
    headerLineCreate: "Create an organization",
    headerLineInvite: "Invite colleagues",
    selectOrganisation: "Select an organization",
    linkFind: "Find an organization",
    linkCreate: "Create an organization",
    name: "organization name",
    send: "confirm",
    installAddOn: "Install the Outlook Add-in",
    goToSite: "Go to GetCollective",
    enterEmailsInvitation: "Email addresses separated with commas",
    enterEmailInvitation: "Email address",
    errorWhileSendingInvitation: "There was an error while sending the invitation(s)",
    someInvitationError: "The following invitation(s) couldn't be sent:",
    invitationsSended: "Invitation(s) sent !",
    invalidEmailsMsg: "Please enter valid email addresses separated with commas",
    invalidEmailMsg: "Please enter a valid email address",
    namePresence: "Name cannot be blank",
    indexTitle: "My organizations",
    organisationsEmpty: "You have not been invited to any organization yet.",
    organisationsEmptyCreateLink: "create an organization",
    searchMembersLabel: "Search for teammates...",
    statusLabel: "Status",
    memberLabel: "Teammate",
    ownerLabel: "Owner",
    invitedBy: "Invited by",
    invitePeopleTo: "Invite teammates to ",
    pendingInvitations: "Pending invitations",
    removeInvitationAlertTitle: "Cancel invitation",
    removeInvitationAlertMessage: "Are you sure you want to cancel this invitation ?",
    picUpdated: "Organization's picture updated successfully",
    picRemoved: "Organization's picture deleted successfully",
    excludeUser: "Delete teammate",
    excludeMemberAlertTitle: "Delete the teammate from the organization",
    excludeMemberAlertMessage: "Are you sure you want to delete this teammate ?",
    invitationsTitle: "My invitations",
    invitationsEmpty: "You don't have any invitation.",
    invitationAccept: "Join",
    invitationDecline: "Discard",
    invitationSentence2: "to join this organization",
    invitationSentence3: "You can accept or decline this invitation",
    invitationAccepted: "The invitation has been accepted",
    invitationDeclined: "The invitation has been declined",
    noPendingInvitation: "There is no pending invitation",
    inviteMembers: "Invite teammates",
    sendInvitations: "Send invitation(s)",
    sendingInvitations: "Sending invitation(s)",
    searchForSomeone: "Search for someone",
    organisationMembers: "Organization's teammates",
    youCanInviteMultiplePeople: "You can invite several teammates at the same time.",
    membersHeaders: {
      members: "Teammates",
      emails: "Email addresses",
      job: "Job title",
      groups: "Teams",
      lastConnexion: "Last log in",
      status: "Status"
    },
    updateOrganisationPicture: "Update organization's picture",
    updatePicture: "Update picture",
    organisationPictureDesc: "This picture will be used in various places such as emails or invitations links"
  },
  cards: {
    removeAlertTitle: "Delete a note",
    removeAlertMessage: "Are you sure you want to delete the note <b><card_title></b> permanently ?",
    removeAlertMessageNoTitle: "Are you sure you want to delete this note permanently ?",
    deleteCard: "Delete note",
    addToCard: "Add to note",
    actions: "Actions",
    archive: "Archive",
    unarchive: "Unarchive",
    tags: "Labels",
    editTag: "Edit label",
    createTag: "Create label",
    createNewTag: "Create a new label",
    searchTags: "Search labels",
    displayArchived: "Display archived notes",
    tagName: "Name",
    tagColor: "Color",
    tagCreate: "Create",
    tagUpdate: "Update",
    tagDelete: "Delete label",
    archivedItems: "Archived items",
    cardIsArchived: "This note is archived.",
    removeTagAlertTitle: "Delete a tag",
    removeTagAlertMessage: "Are you sure you want to delete the tag <b><tag_name></b> permanently ? This will remove the tag from all associated cards definitively.",
    noTitle: "Untitled item",
    noteSaved: "Note saved.",
    saving: "Saving...",
    saveError: "Error while saving.",
  },
  setPassword: {
    headerLine: "Set your password",
    pwd: "password",
    pwdConfirmation: "confirmation",
    success: "Your password was successfully updated",
    send: "Confirm"
  },
  forgotPassword: {
    headerLine: "Forgot your password ?",
    emailSend: "Click on the link you received by email in order to reset your password",
    send: "Send reset link"
  },
  confirmEmail: {
    headerLine: "Email address confirmation",
    loading: "Waiting for confirmation",
    success: "Your email address was successfully confirmed",
    error: "An error occurred",
    plsConfirm: "Please click on the link you received in your mailbox before using GetCollective.",
    emailConfirmed: "I have confirmed my email"
  },
  loginPage: {
    headerLine1: "Sign in and get started",
    headerLine2: "with GetCollective",
    dontHaveAccount: "Don't have an account?",
    signUp: "Sign up",
    email: "Work Email",
    password: "Password",
    continue: "Continue",
    clickHere: "Click here"
  },
  signupPage: {
    headerLine1: "Get started",
    headerLine2: "with GetCollective",
    alreadyHaveAccount: "Already have an account?",
    login: "Sign in",
    email: "Work Email",
    password: "Password",
    signUpSuccess: "You signed up successfully",
    confirmAddress: "Please check your mailbox to continue"
  },
  navigationMenu: {
    newMeeting: "New meeting",
    upcomingMeeting: "Meetings",
    templates: "Templates",
    meetingMinutes: "Meeting minutes",
    tools: "Tools",
    followUps: "Topics",
    followUp: "Topic",
    task: "Task",
    tasks: "Tasks",
    groups: "Teams",
    organisations: "Organizations",
    settings: "Settings",
    webinar: "Webinars",
    needHelp: "Need help",
    projects: "Projects"
  },
  userProfile: {
    editProfile: "Edit user settings",
    logOut: "Logout from GetCollective",
    updatePwdTitle: "Change your password",
    updatePwdSuccess: "Password set successfully",
    updatePwdRedirectMsg: "You will be redirected to the login page",
    profilePicRemoved: "Profile picture deleted",
    profilePicUpdated: "Profile picture updated",
    userUpdated: "The user was successfully updated",
    profile: "Profile",
    notifications: "Notifications",
    integration: "Integrations",
    securityPrivacy: "Security & Privacy",
    profilePicture: "Profile picture",
    change: "Change",
    remove: "Delete",
    currentPwd: "Current password",
    newPwd: "New password",
    newPwdConfirmation: "New password confirmation",
    uploadRequierements: "Uploads must be in jpg or png formats and less than 5 MB",
    firstName: "First name",
    lastName: "Last name",
    saveChanges: "Save changes",
    jobTitle: "Job title",
    notSpecified: "No job title",
    phone: "Phone",
    optional: "optional",
    languages: "Application language",
    notificationsSettings: {
      app: "App",
      email: "Email address",
      microsoftTeams: "Teams",
      meetings: "Meetings",
      newMeeting: "New meeting",
      agendaUpdates: "Agenda updates",
      meetingMinutes: "Meeting minutes",
      tasks: "Tasks",
      assigned: "Assigned",
      dueDate: "Due date",
      done: "Done"
    }
  },
  misc: {
    "404": "Requested item doesn't exist or you don't have permission to view it",
    timer: {
      days: "days",
      day: "day",
      hours: "hours",
      hour: "hour",
      minutes: "min",
      secondes: "sec"
    },
    zoomLevels: {
      weekAndDay: "Day",
      weekAndMonth: "Week",
      weekAndDayLetter: "Week And Day Letter",
      weekDateAndMonth: "Week Date and Month",
      monthAndYear: "Month",
      year: "Quarter"
    },
    colors: {
      red: "Red",
      pink: "Pink",
      purple: "Purple",
      indigo: "Indigo",
      blue: "Blue",
      cyan: "Cyan",
      teal: "Teal",
      green: "Green",
      lime: "Lime",
      yellow: "Yellow",
      orange: "Orange"
    },
    or: "OR",
    invite: "Invite",
    inviteToJoin: "Invite teammates to GetCollective",
    removeFilter: "No filter",
    suggestions: "Suggestions",
    favorite: "Favorite",
    lastActivity: "Last update",
    dangerZone: "Deleting a project is final",
    welcomeToGC: "Welcome to GetCollective",
    planAndPrepare: "In this section you can find all your meetings prepared with GetCollective.<br>To plan and prepare your first meeting:",
    installOutlookAddOn: "Install our Outlook Add-in to automatically add GetCollective to any of your Outlook events",
    createAMeetingFromGC: "or create a meeting from GetCollective",
    optionInstallAddOn: "Install Outlook Add-in",
    optionNewMeeting: "New meeting",
    accessDenied: "Acces Denied",
    returnToMeetingList: "Return to my meetings",
    meetingMinutes: "Meeting minutes",
    failedToConnect: "Failed to connect to GetCollective",
    retryNow: "Retry now",
    attemptToConnectIn: "Attempt to connect in <counter> seconds",
    retrying: "Attempting to connect...",
    today: "Today",
    minutes: "Minutes",
    downloadFile: "Download",
    edit: "Edit",
    search: "Search",
    actions: "Actions",
    privacyPolicy: "Privacy policy",
    termsOfService: "Terms and conditions",
    owner: "Owner",
    organisationOwner: "Admin",
    createdAt: "Created at",
    lastUpdate: "Last updated",
    attendees: "Attendees",
    attendee: "Attendee",
    sharedWith: "Shared with",
    description: "Description",
    title: "Title",
    back: "Back",
    next: "Next",
    end: "End",
    confirm: "Confirm",
    duplicate: "Duplicate",
    archive: "Archive",
    delete: "Delete",
    deleteAreYouSure: "Are you sure you want to <b>permanently delete</b> this item ?",
    deleting: "Deleting",
    loadingData: "Loading data, please wait...",
    file: "File",
    url: "Web address",
    cancel: "Cancel",
    ok: "OK",
    on: "on",
    save: "Save",
    errorOccurred: "An error occurred",
    error: "Error",
    yes: "Yes",
    no: "No",
    pleaseWait: "Please wait...",
    memberOf: "members of",
    close: "Close",
    create: "Create",
    finish: "Finish",
    jobTitle: "Job title",
    noResults: "No results",
    results: "Results",
    remove: "Delete",
    name: "name",
    projects: "Projects",
    contactSales: "Contact our sales team here",
    thanksForSignin: "Thank you for signing up to GetCollective.",
    noOrganisationsSentence: "It looks like your organization is not using GetCollective. To create a new organization and start using GetCollective with your colleagues, please",
    salesAssist: "They will assist you in the process and soon you will be using meetings efficiently to lead your projects.",
    optional: "optional",
    pageNotFound: "Error 404 - Page not found",
    everyone: "Everyone",
    emails: "Email addresses",
    initializingUpload: "Initializing Upload...",
    uploadInProgress: "Upload in progress...",
    uploadComplete: "Upload complete!",
    cgu: "By clicking on « Join » you agree to GetCollective’s terms of service and privacy policy",
    cgu1: "By clicking on « Join » you agree to GetCollective’s",
    cgu2: "terms of service",
    cgu3: "and",
    cgu4: "privacy policy",
    cgu5: null,
    copyright: "© 2021 GetCollective.",
    checklist: "Task list",
    newCard: "New note",
    cards: "Notes",
    then: "then",
    comment: "Comment",
    recurring: "Recurrence"
  },
  headerActions: {
    followUpForm: "New topic",
    addNewGroup: "New team"
  },
  projects: {
    roadmap: "Roadmap",
    projects: "Projects",
    project: "Project",
    allProjects: "All projects",
    searchProjects: "Search for projects...",
    addNewProject: "New project",
    updateExistingProject: "Update an existing project",
    created: "Project created",
    projectColor: "Project color",
    changeProjectColor: "Change project color",
    createProject: "Create project",
    updateProject: "Update project",
    updatedProject: "Project updated",
    removeAlertTitle: "Delete a project",
    deleteProject: "Delete project",
    removeAlertMessage: "Are you sure you want to delete the project <b><project_name></b> permanently ?",
    filterProject: "Filter by project",
    projectSettings: "Project settings",
    projectOverview: "Overview",
    projectComments: "Comments",
    projectBanner: "Upload a project banner",
    removeProjectBanner: "Remove project banner",
    projectStartDate: "Start Date",
    projectEndDate: "Deadline",
    projectName: "Project Name",
    assignedToProject: "Added to project",
    removeMemberAlertTitle: "Remove",
    removeMemberAlertMessage: "Are you sure you want to remove <b><user_name></b> from the project <b><project_name></b>. permanently ?",
    noProjects: "No projects to display",
    owner: "Owner",
    members: "Teammates",
    sharedWith: "Shared with",
    share: "Share",
    noMilestones: "Not Assigned Milestone",
    noMilestonesDesc1: "Milestones are used to track progress toward specific goals or events.",
    noMilestonesDesc2: "The likelihood of projects being delivered successfully is much higher with milestones.",
    addMilestone: "Add a milestone",
    writeAComment: "Write a comment here",
    addFollowUp: "Add a topic",
    noFollowUps: "No topics added to this project."
  },
  followUps: {
    exportPDF: "Download as PDF",
    followUpInfos: "Topic details",
    updated: "Topic updated",
    shareFollowUp: "Share topic",
    createFollowUp: "New topic",
    updateExistingFollowUp: "Update an existing topic",
    updateFollowUp: "Update topic",
    searchFollowUp: "Search for a topic",
    followUpsPerPage: "Topics per page",
    created: "The topic was created successfully",
    removeAlertTitle: "Delete a topic",
    removeAlertMessage: "Are you sure you want to delete the topic <b><followup_name></b> permanently ?",
    removeMemberAlertTitle: "Remove",
    removeMemberAlertMessage: "Are you sure you want to remove <b><user_name></b> from the topic <b><followup_name></b>. permanently ?",
    show: "Go to the topic page",
    followupMembers: "Topic shared with",
    nextMeetings: "Meetings with this topic",
    addOrRemoveUsers: "Share the topic with",
    loadingProjects: "Loading projects...",
    selectProject: "Select a project",
    loadingFollowup: "Loading topic...",
    removeFromProject: "Remove from project",
    removedFromProject: "Topic removed from project.",
    followupMember: "Teammate",
    notVisible: "You don't have the permission to access to this resource.",
    askForInvitation: "Please ask to your colleagues to give you access and reload this page",
    topicEmpty: "The topic is empty",
    followUpEmpty: "The topic is empty",
    noFollowUps: "You have no topics.",
    followUpStatus: "Status",
    followUpsDescription1: "Topics you create can then be added to meetings or projects.",
    followUpsDescription2: "In a topic, you will be able to add and share notes, decisions, files and tasks.",
    createANewFollowUp: "Create a new topic",
    selectExistingFollowUp: "Select an existing topic",
    addTopicsToMeeting: "Add topics",
    addTopicToMeeting: "Add to meeting",
    followUpName: "Search for an existing topic",
    addedToMeeting: "Topic added to meeting.",
    followUpVisibleMsgAddTopic: "All topics added to the meeting will automatically be shared with all participants",
    followUpVisibleMsgAddMember: "All topics added to the meeting are automatically shared with all participants",
    recentlyUsed: "Recently used",
    newFollowup: "New topic",
    newFollowupTitle: "New topic title",
    addToProject: "Add topic to an existing project?",
    removeFromMeeting: "Remove from meeting",
    noMeetingsPlanned: "No meetings planned",
    status: {
      done: "Done",
      pending: "Pending",
      progress: "In Progress",
      late: "Late",
      stuck: "Stuck",
      review: "Reviewing",
      canceled: "Canceled"
    },
    startDate: "Start",
    endDate: "Deadline",
    tasks: "Tasks",
    members: "Teammates",
    name: "Name",
    filters: {
      allSubjects: "All topics",
      mySubjects: "My topics",
      sharedWithMe: "Shared with me",
      favorite: "Favorites"
    }
  },
  meetings: {
    status: {
      pending: "Pending",
      canceled: "Canceled",
      started: "Live",
      paused: "Paused",
      finished: "Finished",
      archived: "Archived"
    },
    changeMeetingsColor: "Change meeting color",
    meetingColor: "Meeting color",
    sidebar: {
      agendaLabel: "Agenda",
      agendaTooltip: "Update topics and durations",
      participantsLabel: "Participants",
      participantsTooltip: "Add or remove participants",
      objectivesLabel: "Objectives",
      objectivesTooltip: "Set meeting objectives",
      detailsLabel: "Details",
      detailsTooltip: "Edit meeting title, description and location",
      minuteLabel: "Minutes",
      minuteTooltip: "Set up and share the meeting minute"
    },
    addATitle: "Add a title",
    untitleMeeting: "Untitled Meeting",
    meetingOrganizer: "Meeting organizer",
    joinMeeting: "Join meeting",
    recurringMeeting: "Recurring meeting",
    unamedMeeting: "Untitled meeting",
    startMeeting: "Start timeline",
    goToNextTopic: "Next topic",
    endMeeting: "End meeting",
    meetingObjectivesCompleted: "Meeting objective(s) completed",
    noDuration: "No duration",
    meetingFinished: "Meeting finished !",
    increaseMeetingDurationTo: "Increase meeting duration to <minutes>min",
    shrinkAllTopicDurations: "Change topics durations to fit in <minutes>min",
    meetingEndTimeConflict: "Total duration of all topics is higher than the meeting duration !",
    meetingEndTimeConflictDescription: "Total duration of all topics is higher than the meeting duration !",
    solveTheConflict: "Solve the problem",
    openMeetingAgenda: "Open meeting agenda",
    editObjectives: "Edit objectives",
    meetingDurationUpdated: "Meeting duration set to <minutes> minutes !",
    meetingDuration: "Meeting duration",
    topicsTotalDuration: "Total topics duration",
    meetingEvents: "topic(s)",
    autoStart: "Automatically start the meeting",
    displayOlders: "Load previous meetings",
    startNow: "Start the timeline now",
    meetingStartIn: "The meeting is scheduled to start in",
    meetingShouldHaveStartedIn: "The meeting was scheduled to start",
    startToPrepare: "Prepare the meeting by adding topics or applying a template.",
    templateLoading: "Apply a template",
    creatingMeeting: "Creating meeting",
    timedTopics: "Topics with durations",
    timedTopicsDescription: "You must add a duration for each topic",
    setTopicAsDone: "Mark topic as done",
    meetingsEmpty: "You have no meeting prepared with GetCollective",
    prepareMeeting: "Meeting preparation",
    fromFollowup: "Choose topic",
    newTopic: "Add topic",
    noTopics: "No topic added",
    addAnotherTopic: "add another topic",
    addFirstTopic: "Add topics",
    inviteParticipants: "Invite participants",
    meetingIsLive: "Live",
    saveTopic: "Add",
    meetingAgenda: "Topics and durations",
    meetingAgendaDescription: "Add topics or apply a template.",
    addATopic: "Add a topic",
    addATopicOrFollowup: "Add topics",
    topic: "Topic",
    shareMeetingMinute: "Send meeting minutes",
    sharedByEmail: "Sent by email",
    minuteNotSharedYet: "Not shared yet",
    meetingSettings: "Meeting settings",
    nextScheduledMeeting: "Next meeting in ",
    meetingForm: "New meeting",
    removeAlertTitle: "Delete meeting",
    removeAlertMessage: "Are you sure you want to delete the meeting <b><meeting_title></b> permanently ?",
    removeSuccessNotification: "Meeting(s) deleted.",
    topicRemoveAlertTitle: "Delete a topic",
    topicRemoveAlertMessage: "Are you sure you want to delete the topic and all its tasks / notes / decisions permanently ?",
    newMeeting: "New meeting",
    editMeetingInfos: "Edit meeting details",
    meetingTitle: "Meeting title",
    editMeeting: "Edit meeting",
    createMeeting: "Create meeting",
    updateMeeting: "Update meeting",
    createRecurrentMeeting: "Create recurrent meeting",
    updateRecurrentMeeting: "Update recurrent meeting",
    loadingMeeting: "Loading meeting",
    details: "Details",
    history: "History",
    assets: "Files",
    addLink: "Add a link to a file in the cloud",
    addFile: "Upload file",
    startTime: "Start time",
    startDate: "Start date",
    endDate: "End date",
    endTime: "End time",
    topicUpdate: "Topic update",
    followupUpdate: "Topic update",
    udpate: "Update",
    maxDuration: "Maximum duration",
    maxDurationOptional: "Maximum duration (optional)",
    durationMinutesOptional: "Duration (optional)",
    inMinutes: "min",
    durationMinutes: "Duration (in minutes)",
    youNeedTimedTopics: "Timed topics disabled",
    youCantEditFollowupName: "Topic title can't be changed",
    youCantEditFollowupDescription: "Topic description can't be changed",
    location: "Location",
    locationNotDefined: "No meeting's location",
    locationDesc: "Meeting's location",
    description: "Description",
    descriptionDesc: "Meeting's description",
    descriptionNotDefined: "No description",
    topicDescriptionNotDefined: "No topic description",
    objectives: "Objectives",
    meetingObjectives: "Meeting Objectives",
    defineMeetingObjectives: "Define objectives",
    objectivesNotDefined: "No objectives",
    objective: "Objective",
    objectivesHeadline: "Define objectives",
    objectivesHeadline2: "Add objectives and mark them as done at the end of the meeting",
    createObjective: "Add an objective",
    deletingMeeting: "Deleting meeting",
    listView: "Meetings List",
    calendarView: "Calendar",
    participants: "Participants",
    meetingInfos: "Meeting details",
    restartMeeting: "Restart meeting",
    addTopic: "Add a topic",
    minutes: "min",
    uploadRequierements: "Size must be less than 20 MB",
    displayOnlyCurrentTopic: "Only from current meeting",
    historyHidden: "Items from other meetings are hidden",
    displayHistoryData: "Show other meetings items",
    currentMeeting: "Current meeting",
    currentTopic: "Current topic",
    nextMeeting: "Next meeting",
    topics: "Topics",
    saveMeetingSettings: "Update meeting settings",
    removeUser: "Remove participant",
    invitedToMeeting: "Invited",
    editDone: "Close editing",
    leave: "Leave meeting",
    leaveAlertTitle: "Leaving this meeting ?",
    leaveAlertMessage: "Are you sure you want to leave this meeting ?",
    fetchFailed: "The meeting doesn't exist or you don't have the permission to see it",
    returnToMeetingList: "Return to my meetings",
    meeting: "Meeting",
    topicSpeaker: "Speaker",
    fromOrganisation: "<organisation> teammates",
    externalCollaborators: "External collaborators",
    exitMeeting: "Exit meeting",
    draftNotEmty: "You have unpublished items.  <b>Are you sure you want to exit? </b><br>Unpublished items will be deleted.",
    addAllMeetingParticipants: "Add all meeting participants",
    reload: "Reload",
    updateRecurrence: "Update recurrent meeting",
    updateRecurrenceOnlyOne: "This meeting only",
    updateRecurrenceOneAndNext: "This meeting and all the following",
    updateRecurrenceOnlyAll: "All meetings",
    updated: 'Meeting updated.',
    deleteRecurrence: "Delete recurrent meeting",
    deleteRecurrenceOnlyOne: "Delete this meeting only",
    deleteRecurrenceOneAndNext: "Delete this meeting and all the following",
    deleteRecurrenceOnlyAll: "Delete all meetings",
    deletedRecurrentMeeting: 'Meeting(s) deleted.'
  },
  feedItems: {
    task: "Task",
    note: "Note",
    decision: "Decision",
    file: "File",
    newTask: "New task",
    newNote: "New note",
    newDecision: "New decision",
    newFile: "New file",
    removeAlertTitle: "Delete a <feed_item_type>",
    removeAlertMessage: "Are you sure you want to delete the following <b><feed_item_type></b> permanently ?",
    taskMember: "Task assignee",
    pinnedFiles: "Pinned Files",
    pinTheFile: "Pin the file",
    unpinTheFile: "Unpin the file",
    attach: "Files",
    attachments: "Attachments"
  },
  feedItemsFilter: {
    all: "All",
    notes: "Notes",
    decisions: "Decisions",
    file: "File",
    tasks: "Tasks",
    files: "Files",
    urls: "Links",
    filter: "Filter:",
    noItems: "No published items",
    noItemsFiltered: "No information matches the search",
    loadOld: "Load previous meetings"
  },
  textEditor: {
    convertToCard: "Convert to note",
    editItem: "Edit",
    editing: "Editing",
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    bulletedList: "Bulleted List",
    numberedList: "Numbered List",
    title: "Title",
    highlight: "Highlight",
    table: "Insert Table",
    deleteTable: "Delete Table",
    addColumnBefore: "Add Column Before",
    addColumnAfter: "Add Column After",
    deleteColumn: "Delete Column",
    addRowBefore: "Add Row Before",
    addRowAfter: "Add Row After",
    deleteRow: "Delete Row",
    toggleCellMerge: "Merge Cells",
    splitCell: "Split Cell",
    publishNote: "Publish Note",
    publishDecision: "Publish Decision",
    publishTask: "Publish Task",
    publishFile: "Publish File",
    insertLink: "Insert Link",
    draft: "Your pad",
    addNote: "Add a note",
    addDecision: "Decision",
    addTask: "Add a task",
    addDecisions: "Decisions",
    addTasks: "Tasks",
    addFile: "Add a file",
    addNoteBelow: "Add a note below",
    addDecisionBelow: "Add a decision below",
    addTaskBelow: "Add a task below",
    addFileBelow: "Add a file below",
    writeYourNoteHere: "Write a note here...",
    writeYourDecisionHere: "Write a decision here...",
    writeYourTaskHere: "Write a task here...",
    setAsNote: "Set as <b>Note</b>",
    setAsDecision: "Set as <b>Decision</b>",
    draftEmpty: "Your pad is empty.",
    startByAdding: 'Click "New note" above to start adding information, decisions, tasks and files',
    publishAll: "Publish All",
    focusEditor: "Focus",
    sideBySide: "Side By Side",
    minimize: "Minimize",
    assignTask: "Assign Task",
    dueDate: "Due Date",
    assignedToTask: "Assigned to task",
    taskAssignedTo: "Task assigned to:",
    insertUrl: "Insert link",
    url: "Web address",
    urlText: "Text"
  },
  templates: {
    createSuccess: "Template was created successfully",
    createTemplate: "Create template",
    empty: "You don't have any template.",
    featureNotAvailable: "The <b>templates</b> feature will be available soon.",
    fromTemplate: "From a template",
    loadATemplate: "Apply a template",
    loadTemplate: "Apply template",
    removeAlertMessage: "Are you sure you want to delete the template <b><template_name></b> permanently ?",
    removeAlertTitle: "Delete a template",
    searchTemplates: "Search for a template",
    templates: "Templates",
    template: "Template",
    templateCreated: "Template created",
    templateName: "Template name",
    templateSuffix: "(template)",
    saveAsTemplate: "Save as template",
    useTemplate: "Use template",
    selectTemplate: "Select a template",
    creatingTemplate: "creating template from meeting",
    loadingTemplates: "Loading templates...",
    templateDescription: "You can save this meeting as a template.",
    templateFeatureDesc: "You have no meeting template.",
    templateFeatureDesc2: "A template is a list of pre-set topics that you can add to a meeting.",
    templateFeatureDesc3: "A template is a list of pre-set topics that you can add to a meeting."
  },
  groups: {
    visibility: "Visibility:",
    createGroup: "New team",
    addMembers: "Add members",
    privateGroup: "Private",
    publicDescription: "Everyone in the organization can see this team",
    privateDescription: "Only the team members and the organization's admin can see this team",
    publicGroup: "Public",
    groups: "Teams",
    groupDesc: "Teams make it easy to invite and share items with groups of people",
    member: "member",
    members: "members",
    name: "Name",
    groupMembers: "Team members",
    addUser: "Add to the team",
    removeUser: "Remove from the team",
    renameGroup: "Rename team",
    deleteGroup: "Delete team",
    groupName: "Team name",
    empty: "The team has no member",
    deleteGroupAlertTitle: "Delete the team from the organization",
    deleteGroupAlertMessage: "Are you sure you want to permanently delete the team <b><groupname></b> from the organization ?",
    deleteGroupUserAlertTitle: "Remove member from team",
    deleteGroupUserAlertMessage: "Are you sure you want to remove <b><username></b> from <b><groupname></b> ?",
    memberOfTheGroup: "Member of the team"
  },
  inputRules: {
    cantBeNegative: "Can't be a negative value",
    cantBeNull: "Can't be null",
    maxTextLength: "Max <length> characters"
  },
  integration: {
    outlookExtension: "Microsoft Outlook Add-in",
    getItNow: "Get it now"
  },
  fileViewer: {
    fileViewer: "File viewer",
    fullscreen: "Fullscreen",
    standardView: "Exit fullscreen",
    download: "Download",
    closeFile: "Close file",
    selectFile: "Select a file"
  },
  meetingMinutes: {
    close: "Back to meeting",
    share: "Share",
    shareMeetingMinute: "Share meeting minute",
    downloadPdf: "Download PDF",
    minuteSettings: "Minutes settings",
    meetingMinutePreview: "Meeting minutes preview",
    companyLogo: "Minutes logo",
    importLogo: "Import logo",
    summary: "Tasks and decisions summary",
    decisions: "Decisions",
    tasks: "Tasks",
    meetingDetails: "Meeting details",
    description: "Description",
    location: "Location",
    objectives: "Objectives",
    participants: "Participants",
    list: "Show participants",
    attendees: "Attendance record",
    sharingMinute: "Sending minute...",
    youCanShareSeveral: "You can add several email at the same time.",
    hasBeenShared: "Meeting minute has been shared."
  },
  email: {
    to: "Add email addresses or users",
    selectRecipients: "Add email addresses or users",
    checkForErrors: "Please make sure that all email addresses are correct."
  },
  tasks: {
    noTasks: "No tasks to display",
    done: "Done",
    search: "Search for a task",
    allTask: "All tasks",
    completedTasks: "Completed tasks",
    incompleteTasks: "Incomplete tasks",
    myTasks: "My tasks",
    task: "Task",
    tasks: "Tasks",
    dueDate: "Due",
    all: "All",
    priority: "Priority",
    context: "From the note",
    assignedTo: "Assigned to",
    everyone: "Everyone",
    project: "Project",
    deleteTask: "Delete task",
    priorities: {
      low: "Low",
      medium: "Medium",
      high: "High",
      critical: "Critical"
    },
    closeTaskDetails: "Close details",
    markComplete: "Mark complete",
    completed: "Completed",
    goToTopic: "Go to topic",
    taskBelongsTo: "This task is from the following note:",
    removeAlertTitle: "Delete a task",
    removeAlertMessage: "Are you sure you want to delete the task <b><task_content></b> permanently ?"
  },
  calendar: {
    month_0: "January",
    month_1: "February",
    month_2: "March",
    month_3: "April",
    month_4: "May",
    month_5: "June",
    month_6: "July",
    month_7: "August",
    month_8: "September",
    month_9: "October",
    month_10: "November",
    month_11: "December",
    day: "Day",
    days: "Days",
    days4: "4 days",
    week: "Week",
    weeks: "Weeks",
    month: "Month",
    months: "Months",
    year: "Year",
    years: "Years",
    nextDay: "Next day",
    previousDay: "Previous day",
    nextWeek: "Next week",
    previousWeek: "Previous week",
    nextMonth: "Next month",
    previousMonth: "Previous month",
    nextPeriod: "Next period",
    previousPeriod: "Previous period",
    recurring: "Recurring",
    recurrence: {
      repeat: "Repeat",
      doesNotRepeat: "Does not repeat",
      daily: "Daily",
      weeklyOn: "Weekly on <day>",
      monthlyOn: "Monthly on <day>",
      monthlyOnFirst: "Monthly on the first <day>",
      monthlyOnSecond: "Monthly on the second <day>",
      monthlyOnThird: "Monthly on the third <day>",
      monthlyOnFourth: "Monthly on the fourth <day>",
      monthlyOnLast: "Monthly on the last <day>",
      annuallyOn: "Annualy on <month> <day-number>",
      everyWeekDay: "Every weekday (Monday to Friday)",
      custom: "Custom..."
    },
    customRecurrence: "Custom recurrence",
    repeatEvery: "Repeat every",
    repeatOn: "Repeat on",
    ends: "Ends",
    occurences: "occurences",
    occurence: "occurence",
    weekdayShort: ["L", "M", "M", "J", "V", "S", "D"],
    endsNever: "Never",
    endsOn: "On",
    endsAfter: "After",
    planning: "Planning",
  }
};
