<template>
  <v-app id="app" :style="cssProps">
    <notification />
    <destroyDialog />

    <navigationMenu v-if="displayMenu" ref="navigationMenu" />
    <v-main>
      <router-view
        class="child-view"


      />
    </v-main>
  </v-app>
</template>

<script>
import navigationMenu from "@/components/layout/navigationMenu";
import notification from "@/components/layout/notification";
import destroyDialog from "@/components/shared/destroyDialog";
import { getInstance } from "@/plugins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "app",
  components: { navigationMenu, notification, destroyDialog },
  data() {
    return {
      transitionName: "fade",
      authService: null,
    };
  },
  computed: {
    displayMenu() {
      if (this.$route && this.$route.name === null) return false; // On router initialisation
      if (this.publicPages.includes(this.$route.path)) return false;
      if (this.$route.path == "/set_organisation") return false;
      if (this.$route.path == "/page_not_found") return false;
      // if (this.$route.name == "meeting_show") return false;
      // if (this.$route.name == "followup_show") return false;
      return true;
    },
    cssProps() {
      /* This props allow us to load vuetify themes variable and access it into scss files */
      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
  },
  methods: {
    ...mapActions(["getCurrentUser"]),


  },
  mounted() {
    this.initialize();
    this.authService = getInstance();
  },
  watch: {
    "authService.isAuthenticated"(val) {
      if (val) {
        if (!this.authService.user.email_verified) {
          this.$router.push({ name: "confirm_email" });
          return;
        }
        this.getCurrentUser();
      }
    },
  },
};
</script>

<style lang="scss">
#tmp {
  display: flex;
}
</style>

<style >
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
