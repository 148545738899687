import { createConsumer } from "@rails/actioncable"
import Bugsnag            from '@bugsnag/js'

function getWebSocketURL() {
  const token = localStorage.accessToken;
  return `${process.env.VUE_APP_API_URL}/cable?token=${token}`;
}

var consumer = createConsumer(getWebSocketURL);
var subscriptions = {};

export function connectToChannel(channel, id, dispatch) {
  console.log('connect to channel : ' + channel);
  let sub = consumer.subscriptions.create({
    channel: channel,
    id: id
  }, {
    initialized() {},
    connected() {},
    disconnected() {
      console.log('WS CONNECTION disconnected');
    },
    rejected() {
      if (process.env.VUE_APP_BUGSNAG_API_KEY) {
        Bugsnag.notify('WS CONNECTION rejected');
      }
    },
    update() {},
    received(data) {
      dispatch(data.operation, data.params);
    },
  });
  subscriptions[id] = sub;
}

export function disconnectFromChannel(id) {
  let sub = consumer.subscriptions.subscriptions.find(e => e.identifier.includes(id))
  if (sub) consumer.subscriptions.remove(sub);
}

