import gql from "graphql-tag";

export default {
  methods: {
    sendQuery(query, variables) {
      return new Promise((resolve, reject) => {
        this.$apollo
          .query({
            query: query,
            variables: variables,
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendMutation(action, resource_name, mutation, variables) {
      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate({
            mutation: mutation,
            variables: variables
          })
          .then(({ data }) => {
            if (action != "no-notif" && action != "no-notif-no-error")
              this.sendSuccessMutation({
                resource_name: resource_name,
                action: action
              });
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};
