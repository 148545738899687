<template>
  <div>
    <v-list-group color="white" active-class="white--text" :value="false">
      <template v-slot:activator>
        <v-icon class="mx-4">mdi-folder-text-outline</v-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("projects.projects") }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <draggable v-model="projects">
      <v-list-item
        v-for="(project, i) in projects"
        :key="i"
        dark
        dense
        class="mx-8"
        @click="goToProject(i)"
      >
        <v-list-item-title class="white--text"
          ><v-btn icon small width="20px" height="20px" class="mr-2">
            <v-icon :color="project.color" small
              >mdi-checkbox-blank-circle</v-icon
            >
          </v-btn>
          {{ project.name }}</v-list-item-title
        >
      </v-list-item></draggable>

      <!-- Add a project -->
      <v-list-item dense @click.stop="newProjectDialog = true" class="mx-8">
      
        <v-list-item-title> <v-icon class="mr-2">mdi-plus</v-icon>{{
          $t("projects.addNewProject")
        }}</v-list-item-title>
      </v-list-item>
    </v-list-group>

    <v-dialog
      v-model="newProjectDialog"
      max-width="480"
      transition="slide-x-transition"
    >
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("projects.addNewProject") }}</span>
          <v-btn icon @click="newProjectDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-5">
          <v-text-field
            v-model="newProjectName"
            :label="$t('misc.title')"
            placeholder=""
            filled
            dense
            autocomplete="off"
          />
          <v-subheader>{{ $t("projects.projectColor") }}</v-subheader>
          <v-row wrap no-gutters justify="center">
            <v-btn
              icon
              depressed
              text
              v-for="color in selectableColors"
              :key="color"
              @click="selectedColor = color"
            >
              <v-icon :large="selectedColor == color" :color="color"
                >mdi-checkbox-blank-circle</v-icon
              >
            </v-btn>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="white--text"
            depressed
            :disabled="newProjectName == ''"
            @click="createProject()"
          >
            {{ $t("projects.createProject") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import draggable from 'vuedraggable';


export default {
  name: "projectsNavigation",
  mixins: [],
  components: {draggable},
  data() {
    return {
      projects: [],
      newProjectDialog: false,
      newProjectName: "",
      selectableColors: [
        "#f44336",
        "#E91E63",
        "#9C27B0",
        "#2196F3",
        "#00BCD4",
        "#8BC34A",
        "#FFEB3B",
        "#FF9800",
        "#795548",
        "#607D8B",
      ],
      selectedColor: "#f44336",
    };
  },
  methods: {
    createProject() {
      this.projects.push({
        name: this.newProjectName,
        color: this.selectedColor,
      });
      console.log(this.projects);
      this.newProjectName = "";
      this.selectedColor = "#f44336";
      this.newProjectDialog = false;
    },
    goToProject(index) {
      //
    },
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
};
</script>
