import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        purple_alert_text: "#5454A3",
        purple_alert: "#E6E6F0",
        primary: '#00a1ff',
        secondary: '#1A2D6C',
        main_blue: '#213B93',
        button_blue: '#00a1ff',
        button_blue_secondary: "#D2DBF7",
        black: '#212121',
        dark_grey: '#393939',
        mid_grey: '#E5E5E5',
        light_grey: '#F7F7FA',
        button_outline: '#b2b3b5',
        caption_text: '#a2a2a2',
        title_dark_blue: '#1F2B4B',
        timeline_blue: '#1F2B4B',
        timeline_lighter_blue: "#2f3b5d",
        header_dark_blue: '#1B2540',
        active_purple: '#E8EDFB',
        text_field_background: "#F7F7FA",
        almost_black: "#212121",
        success: "#63C791",
        item_task: "#46a4bf",
        item_task_background: "#dbf1f8",
        item_note: "#454e68",
        item_note_background: "#d4d4d9",
        item_decision: "#ff356a",
        item_decision_background: "#ffeaf0",
        item_file: "#9a9a9a",
        item_file_background: "#f4f4f4",
        next_meeting: "#ff8a74",
        light_borders: "#cbd4db",
        button_text_grey: "#676e78",
        inputPlaceholder: "#0042c8",
        tag_color_1: "#63BD4D",
        tag_color_2: "#FBC02D",
        tag_color_3: "#FF9800",
        tag_color_4: "#F44336",
        tag_color_5: "#EC407A",
        tag_color_6: "#8E24AA",
        tag_color_7: "#3F51B5",
        tag_color_8: "#2196F3",
        tag_color_9: "#00BCD4",
        tag_color_10: "#009688",
        archived_alert: "#FDFAE5",
        nextMeeting: "#ffedd5",
        calendarToday: "#F28F2D",
      },
    },
  },
});
