import pluralize from "pluralize";

const state = {
  //current_users: { refresh: 0 },
  organisations: { refresh: 0 },
  organisation_users: { refresh: 0 },
  users: { refresh: 0 },
  recurrent_meetings: {
    refresh: 0,
    parents: ['organisations']
  },
}

const getters = {
  organisations_set: state => state.organisations,
  organisation_users_set: state => state.organisation_users,
  users_set: state => state.users,
  recurrent_meetings_set: state => state.recurrent_meetings,
  organisations_refresh: state => state.organisations.refresh,
  organisation_users_refresh: state => state.organisation_users.refresh,
  recurrent_meetings_refresh: state => state.recurrent_meetings.refresh,
  users_refresh: state => state.users.refresh,
  currentUser: (state, getters) => {
    getters.users_refresh;
    if (!getters.current_user_id) return null;
    return state.users[getters.current_user_id];
  },
  organisation_users: (state, getters) => {
    getters.users_refresh;
    getters.organisation_users_refresh;
    if (!getters.selectedOrganisationId) return [];
    let organisation = state.organisations[getters.selectedOrganisationId];
    if (!organisation) return [];
    return organisation.organisation_user_ids.map(id => {
      return state.organisation_users[id];
    });
  },
  organisation_recurrent_meeting_ids: (state, getters) => {
    getters.recurrent_meetings_refresh;
    if (!getters.selectedOrganisationId) return [];
    let organisation = state.organisations[getters.selectedOrganisationId];
    if (!organisation) return [];
    return organisation.recurrent_meeting_ids
  },
  organisation_recurrent_meetings: (state, getters) => {
    return getters.organisation_recurrent_meeting_ids.map(id => {
      return state.recurrent_meetings[id];
    });
  }
}

const actions = {
  setInstances({ commit }, { model, instances }) {
    instances.forEach(instance => commit("setInstance", { model, instance }));
  },
  setInstance({ commit }, { model, instance }) {
    commit("setInstance", { model, instance });
  },
  removeInstance({ commit }, { model, instance_id, parents }) {
    commit("removeInstance", { model, instance_id, parents });
  },
  setSubField({ commit }, { model, id, field, value }) {
    commit("setSubField", { model, id, field, value });
  },
  refreshModel({ commit }, model) {
    commit("refreshModel",  model);
  }
}

function recSetInstance(state, model, instance, parent) {
  let singular_key;
  /* Iterate over all fields of the current object */
  for (let key in instance) {
    if (instance[key] !== null && typeof instance[key] === "object") {
      if (key.endsWith('_id') || key.endsWith('_ids')) continue;

      if (Array.isArray(instance[key])) {
        singular_key = pluralize.singular(key);
        /*
          If field is an array
          - We store all element ids in the parent
          - We run recursively the same process on array elements
        */
        instance[`${singular_key}_ids`] = instance[key].map(e => e.id)
        instance[key].forEach(e => {
          recSetInstance(state, singular_key, e, { 'model': model, id: instance.id });
        });
      } else {
        /*
          If field is an object (not array)
          - We store its id in the parent
          - We run recursively the same process on the element
        */
        instance[`${key}_id`] = instance[key].id
        recSetInstance(state, key, instance[key], { 'model': model, id: instance.id })
      }
      delete instance[key]
    }
  }

  let pluralized_model = pluralize(model)
  if (!state[pluralized_model]) {
    console.log('Uninitialized object store', pluralized_model)
    state[pluralized_model] = {};
  }

  if (parent && parent.model && parent.id) {
    /* we are setting a child recursively */
    let singular_parent_model = pluralize.singular(parent.model)
    let pluralized_parent_model = pluralize(parent.model)
    if (!state[pluralized_model].parents)
    state[pluralized_model].parents = [];
    /* Keep reference of the parent id in the model instance */
    if (!state[pluralized_model].parents.includes(pluralized_parent_model))
    state[pluralized_model].parents.push(pluralized_parent_model)
    /* Keep reference of the parent model name in the model set */
    if (!instance[`${singular_parent_model}_id`])
      instance[`${singular_parent_model}_id`] = parent.id;
  }
  if (state[pluralized_model].parents) {
    /*
      We are not setting an object recursively and the object have parent(s)
      i.e. the object has previously been set recursively through another model
    */
    let singularized_model = pluralize.singular(model)

    state[pluralized_model].parents.forEach(parent_model => {
      let singular_parent_model = pluralize.singular(parent_model)
      if (instance[`${singular_parent_model}_id`]) {
        let parent_id = instance[`${singular_parent_model}_id`]
        /* Update parent association field if it exists */
        if (!state[parent_model]) return

        let p = state[parent_model][parent_id];
        if (!p) return;

        if (p[`${singularized_model}_ids`] &&
            Array.isArray(p[`${singularized_model}_ids`]) &&
            !p[`${singularized_model}_ids`].includes(instance.id)) {
          console.log('PUSHED ', instance.id, p[`${singularized_model}_ids`])

          p[`${singularized_model}_ids`].push(instance.id);
          state[parent_model][parent_id] = p;
        }
      }
    })
  }

  state[pluralized_model][instance.id] = instance;
  //state[model][instance.id] = { ...state[model][instance.id], ...instance };
  state[pluralized_model].refresh++;

}

const mutations = {
  setInstance(state, { model, instance }) {
    recSetInstance(state, model, instance, parent);
  },
  removeInstance(state, { model, instance_id, parents}) {
    let pluralized_model = pluralize(model)
    let singular_model = pluralize.singular(model)

    if (!state[pluralized_model]) {
      console.log('Try to remove an unexisting object, skipping', pluralized_model);
      return
    }

    let instance = state[pluralized_model][instance_id];
    if (!instance) return;

    /* Remove parent associations */
    state[pluralized_model].parents.forEach(parent_model => {
      let pluralized_parent_model = pluralize(parent_model)
      let singularized_parent_model = pluralize.singular(parent_model)

      if (!state[pluralized_parent_model]) return

      let parent_id = instance[`${singularized_parent_model}_id`]
      let parent_ids = instance[`${singularized_parent_model}_ids`] || []

      parent_ids.concat(parent_id).forEach(parent_id => {
        let parent_instance = state[pluralized_parent_model][parent_id]
        if (!parent_instance) return

        if (parent_instance[`${singular_model}_ids`])
          parent_instance[`${singular_model}_ids`] = parent_instance[`${singular_model}_ids`].filter(id => id != instance_id);

        if (parent_instance[`${singular_model}_id`])
          parent_instance[`${singular_model}_id`] = null;

        state[pluralized_parent_model][parent.id] = parent_instance;
      })
    })

    delete state[pluralized_model][instance_id];

  },
  setSubField(state, { model, id, field, value }) {
    let pluralized_model = pluralize(model)
    if (!state[pluralized_model]) {
      console.log('Try to set an unexisting object, skipping', pluralized_model);
      return
    }
    let instance = state[pluralized_model][id];
    if (!instance) {
      console.log('Try to set an unexisting object, skipping', pluralized_model, id);
      return
    }
    state[pluralized_model][id][field] = value;
  },
  refreshModel(state, model) {
    let pluralized_model = pluralize(model)
    if (!state[pluralized_model]) {
      console.log('Try to refresh an unexisting object, skipping', pluralized_model);
      return
    }
    state[pluralized_model].refresh++;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
