export default {
  organisations: {
    headerLineJoin: "Rejoindre une organisation",
    headerLineCreate: "Créer une organisation",
    headerLineInvite: "Inviter des collègues",
    selectOrganisation: "Sélectionner une organisation",
    linkFind: "Trouver une organisation",
    linkCreate: "Créer une organisation",
    name: "nom de l'organisation",
    send: "confirmer",
    installAddOn: "Installez le Complément Outlook",
    goToSite: "Aller sur GetCollective",
    enterEmailsInvitation: "Adresses email séparées par une virgule",
    enterEmailInvitation: "Adresse email",
    errorWhileSendingInvitation: "Une erreur s'est produite lors de l'envoi des des invitations",
    someInvitationError: "Les invitations suivantes n'ont pu être envoyées:",
    invitationsSended: "Invitation(s) envoyée(s) !",
    invalidEmailsMsg: "Veuillez entrer des adresses email valides et séparées par une virgule",
    invalidEmailMsg: "Veuillez entrer une adresse email valide",
    namePresence: "Le nom ne peut pas être vide",
    indexTitle: "Mes organisations",
    organisationsEmpty: "Vous n'avez pas encore été invité à une organisation.",
    organisationsEmptyCreateLink: "Créer une organisation",
    searchMembersLabel: "Chercher parmi les collègues...",
    statusLabel: "Statuts",
    memberLabel: "Collègue",
    ownerLabel: "Propriétaire",
    invitedBy: "Invité par",
    pendingInvitations: "Invitations en attente",
    removeInvitationAlertTitle: "Annuler l'invitation",
    removeInvitationAlertMessage: "Êtes-vous sûr de vouloir annuler cette invitation ?",
    picUpdated: "La photo de l'organisation a été modifiée avec succès",
    picRemoved: "La photo de l'organisation a été supprimée avec succès",
    excludeUser: "Supprimer ce collègue",
    excludeMemberAlertTitle: "Supprimer le collègue de cette organisation",
    excludeMemberAlertMessage: "Êtes-vous sûr de vouloir supprimer ce collègue ?",
    invitationsTitle: "Mes invitations",
    invitationsEmpty: "Vous n'avez pas d'invitation",
    invitationAccept: "Rejoindre",
    invitationDecline: "Annuler",
    invitationSentence2: "pour rejoindre cette organisation",
    invitationSentence3: "Vous pouvez accepter ou décliner cette invitation",
    invitationAccepted: "L'invitation a été acceptée",
    invitationDeclined: "L'invitation a été refusée",
    noPendingInvitation: "Il n'y a pas d'invitation en attente",
    inviteMembers: "Inviter des collègues",
    sendInvitations: "Envoyer une invitation",
    sendingInvitations: "Invitation(s) en cours d'envoi",
    searchForSomeone: "Chercher quelqu'un",
    organisationMembers: "Collègues de l'organisation",
    youCanInviteMultiplePeople: "Vous pouvez inviter plusieurs collègues en même temps.",
    membersHeaders: {
      members: "Collègues",
      emails: "Adresse email",
      job: "Intitulé de poste",
      groups: "Équipes",
      lastConnexion: "Dernière connexion",
      status: "Status"
    },
    updateOrganisationPicture: "Modifier la photo de l'organisation",
    updatePicture: "Modifier la photo",
    organisationPictureDesc: "Cette photo sera utilisée à différents endroits tels que dans des emails ou les liens d'invitations",
    invitePeopleTo: "Invitez des collègues à"
  },
  cards: {
    removeAlertTitle: "Supprimer une note",
    removeAlertMessage: "Etes vous sûr de vouloir supprimer la note <b><card_title></b> de façon permanente ?",
    removeAlertMessageNoTitle: "Etes vous sûr de vouloir supprimer cette note de façon permanente ?",
    deleteCard: "Supprimer la note",
    addToCard: "Ajouter à la note",
    actions: "Actions",
    archive: "Archiver",
    unarchive: "Désarchiver",
    tags: "Étiquettes",
    editTag: "Mettre à jour l'étiquette",
    createTag: "Créer une étiquette",
    createNewTag: "Créer une nouvelle étiquette",
    searchTags: "Rechercher une étiquette",
    displayArchived: "Afficher les notes archivées",
    tagName: "Nom",
    tagColor: "Couleur",
    tagCreate: "Créer",
    tagUpdate: "Sauvegarder",
    tagDelete: "Supprimer l'étiquette",
    archivedItems: "Élements archivés",
    cardIsArchived: "Cette note est archivée.",
    removeTagAlertTitle: "Supprimer une étiquette",
    removeTagAlertMessage: "Etes vous sûr de vouloir supprimer l'étiquette <b><tag_name></b> de façon permanente ? Cette action supprimera l'étiquette de toute les cartes associées.",
    noTitle: "Note sans titre",
    noteSaved: "Modifications sauvegardées.",
    saveError: "Erreur lors de l'enregistrement.",
    saving: "Enregistrement..."
  },
  setPassword: {
    headerLine: "Définir votre mot de passe",
    pwd: "mot de passe",
    pwdConfirmation: "confirmation",
    success: "Votre mot de passe a bien été modifié",
    send: "Confirmer"
  },
  forgotPassword: {
    headerLine: "Vous avez oublié votre mot de passe ?",
    emailSend: "Cliquez sur le lien que vous avez reçu par email pour réinitialiser votre mot de passe",
    send: "Envoyer le lien de réinitialisation"
  },
  confirmEmail: {
    headerLine: "Confirmation de l'adresse email",
    loading: "En attente de confirmation",
    success: "Votre adresse email a bien été confirmée",
    error: "Une erreur s'est produite",
    plsConfirm: "Cliquer sur le lien reçu dans votre boite mail avant d'utiliser GetCollective.",
    emailConfirmed: "J'ai confirmé mon email"
  },
  loginPage: {
    headerLine1: "Connectez vous et commencez",
    headerLine2: "à utiliser GetCollective",
    dontHaveAccount: "Vous n'avez pas encore de compte ?",
    signUp: "Inscription",
    email: "Email professionnel",
    password: "Mot de passe",
    continue: "Continuer",
    clickHere: "Cliquez ici"
  },
  signupPage: {
    headerLine1: "Inscrivez vous et commencez",
    headerLine2: "à utiliser GetCollective",
    alreadyHaveAccount: "Vous avez déjà un compte ?",
    login: "Connectez vous",
    email: "Email professionnel",
    password: "Mot de passe",
    signUpSuccess: "Vous vous êtes inscrit(e) avec succès",
    confirmAddress: "Veuillez consulter vos emails pour continuer"
  },
  navigationMenu: {
    newMeeting: "Nouvelle réunion",
    upcomingMeeting: "Réunions",
    templates: "Modèles",
    meetingMinutes: "Comptes rendus",
    tools: "Outils",
    followUps: "Sujets",
    followUp: "Sujet",
    task: "Tâche",
    groups: "Équipes",
    organisations: "Organisations",
    settings: "Paramètres",
    webinar: "Webinar",
    needHelp: "Besoin d'aide",
    projects: "Projets",
    tasks: "Tâches"
  },
  userProfile: {
    editProfile: "Modifier les paramètres utilisateur",
    logOut: "Se déconnecter de GetCollective",
    updatePwdTitle: "Changer votre mot de passe",
    updatePwdSuccess: "Mot de passe définit avec succès",
    updatePwdRedirectMsg: "Vous allez être redirigé vers la page de connexion",
    profilePicRemoved: "Photo de profil supprimée",
    profilePicUpdated: "Photo de profile modifiée",
    userUpdated: "L'utilisateur a bien été modifié",
    profile: "Profil",
    notifications: "Notifications",
    integration: "Intégrations",
    securityPrivacy: "Sécurité et confidentialité",
    profilePicture: "Photo de profile",
    change: "Changer",
    remove: "Supprimer",
    currentPwd: "Mot de passe actuel",
    newPwd: "Nouveau mot de passe",
    newPwdConfirmation: "Confirmation du nouveau mot de passe",
    uploadRequierements: "Les chargements doivent être au format jpg ou png et moins de 5 Mo",
    firstName: "Prénom",
    lastName: "Nom",
    saveChanges: "Sauvegarder les changements",
    jobTitle: "Intitulé de poste",
    notSpecified: "Pas d'intitulé de poste",
    phone: "Téléphone",
    optional: "optionnel",
    languages: "Langue de l'application",
    notificationsSettings: {
      app: "App",
      email: "Adresse email",
      microsoftTeams: "Équipes",
      meetings: "Réunions",
      newMeeting: "Nouvelle réunion",
      agendaUpdates: "Mises à jour du calendrier",
      meetingMinutes: "Comptes rendus",
      tasks: "Tâches",
      assigned: "Assigné(e)",
      dueDate: "Date d'échéance",
      done: "Fait"
    }
  },
  misc: {
    "404": "L'élément demandé n'existe pas ou vous n'êtes pas autorisé à le voir",
    timer: {
      days: "jours",
      day: "jour",
      hours: "heures",
      hour: "heure",
      minutes: "min",
      secondes: "sec"
    },
    accessDenied: "Accès Restreint",
    returnToMeetingList: "Retourner à mes réunions",
    meetingMinutes: "Compte rendu",
    failedToConnect: "Échec de connexion à GetCollective",
    retryNow: "Réessayer maintenant",
    attemptToConnectIn: "Nouvelle tentative de connexion dans <counter> secondes",
    retrying: "Tentative de connexion...",
    today: "Aujourd'hui",
    minutes: "Minutes",
    downloadFile: "Téléchargement",
    edit: "Modifier",
    search: "Chercher",
    actions: "Actions",
    privacyPolicy: "Politique de confidentialité",
    termsOfService: "Conditions générales",
    owner: "Propriétaire",
    organisationOwner: "Administrateur",
    createdAt: "Créé(e) le",
    lastUpdate: "Dernière modification",
    attendees: "Participants",
    attendee: "Participant",
    description: "Description",
    title: "Titre",
    back: "Retour",
    next: "Suivant",
    end: "Fin",
    confirm: "Confirmer",
    duplicate: "Dupliquer",
    archive: "Archiver",
    delete: "Supprimer",
    deleteAreYouSure: "Êtes-vous sûr de vouloir <b>supprimer pour toujours</b> cet élément ?",
    deleting: "En cours de suppression",
    loadingData: "Chargement des données, veuillez patienter...",
    file: "Fichier",
    url: "Addresse web",
    cancel: "Annuler",
    ok: "OK",
    on: "sur",
    save: "Sauvegarder",
    errorOccured: "Une erreur s'est produite",
    error: "Erreur",
    yes: "Oui",
    no: "Non",
    pleaseWait: "Veuillez patienter...",
    memberOf: "membres de",
    close: "Fermer",
    create: "Créer",
    finish: "Terminer",
    jobTitle: "Intitulé de poste",
    noResults: "Aucun résultat",
    results: "Résultats",
    remove: "Supprimer",
    name: "nom",
    projects: "Projets",
    contactSales: "Contacter notre équipe commerciale ici",
    thanksForSignin: "Merci de vous être inscrit(e) à GetCollective",
    noOrganisationsSentence: "Il semble que votre organisation n'utilise pas GetCollective. Pour créer une nouvelle organisation et commencer à utiliser GetCollective avec vos collègues, veuillez",
    salesAssist: "Ils vous assisteront dans le processus et vous utiliserez bientôt les réunions de manière efficace pour faire avancer vos projets.",
    optional: "optionnel",
    pageNotFound: "Erreur 404 - Page non trouvée",
    everyone: "Tout le monde",
    errorOccurred: "Une erreur est survenue",
    emails: "Addresses emails",
    cgu1: "En cliquant sur « Rejoindre » vous acceptez les",
    cgu2: "conditions d'utilisation",
    cgu3: "et la",
    cgu4: "politique de confidentialité",
    cgu5: "de GetCollective.",
    copyright: "© 2021 GetCollective.",
    initializingUpload: "Démarrage du chargement...",
    uploadInProgress: "Chargement en cours...",
    uploadComplete: "Chargement terminé !",
    cgu: 'En cliquant sur "Rejoindre" vous acceptez les conditions générales et la politique de confidentialité de GetCollective',
    then: "ensuite",
    checklist: "Liste de tâches",
    newCard: "Nouvelle note",
    cards: "Notes",
    welcomeToGC: "Bienvenue sur GetCollective",
    planAndPrepare: "Retrouvez dans cette rubrique toutes vos réunions préparées avec GetCollective.<br>Pour planifier et préparer votre première réunion :",
    installOutlookAddOn: "Installez notre Complément Outlook pour ajouter automatiquement GetCollective à n'importe lequel de vos événements Outlook",
    createAMeetingFromGC: "ou bien créez directement une réunion avec GetCollective",
    optionInstallAddOn: "Installer le Complément Outlook",
    optionNewMeeting: "Nouvelle réunion",
    zoomLevels: {
      weekAndDay: "Jour",
      weekAndMonth: "Semaine",
      weekAndDayLetter: null,
      weekDateAndMonth: null,
      monthAndYear: "Mois",
      year: "Trimestre"
    },
    colors: {
      red: "Rouge",
      pink: "Rose",
      purple: "Violet",
      indigo: "Indigo",
      blue: "Bleu",
      cyan: "Cyan",
      teal: "Bleu sarcelle",
      green: "Vert",
      lime: "Vert citron",
      yellow: "Jaune",
      orange: "Orange"
    },
    or: "OU",
    dangerZone: "Supprimer un projet est définitif",
    comment: "Commentaire",
    suggestions: "Suggestions",
    favorite: "Favoris",
    lastActivity: "Dernière modification",
    sharedWith: "Partagé avec",
    invite: "Inviter",
    inviteToJoin: "Inviter des collègues sur GetCollective",
    removeFilter: "Pas de filtre"
  },
  headerActions: {
    followUpForm: "Nouveau sujet",
    addNewGroup: "Nouvelle équipe"
  },
  projects: {
    projects: "Projets",
    project: "Projet",
    allProjects: "Tous les projets",
    searchProjects: "Chercher parmi les projets...",
    addNewProject: "Nouveau projet",
    updateExistingProject: "Modifier un projet",
    created: "Projet créé",
    projectColor: "Couleur du projet",
    createProject: "Créer un projet",
    updateProject: "Modifier le projet",
    updatedProject: "Projet modifié",
    removeAlertTitle: "Supprimer un projet",
    removeAlertMessage: "Êtes-vous sûr de vouloir supprimer définitivement le projet <b><project_name></b> ?",
    filterProject: "Trier par projets",
    roadmap: "Planning",
    changeProjectColor: "Changer la couleur du projet",
    deleteProject: "Supprimer le projet",
    projectSettings: "Paramètres du projet",
    projectOverview: "Vue d'ensemble",
    projectComments: "Commentaires",
    projectBanner: "Ajouter une bannière au projet",
    removeProjectBanner: "Enlever la bannière du projet",
    projectStartDate: "Date de début",
    projectEndDate: "Echéance",
    projectName: "Nom du projet",
    assignedToProject: "Ajouté au projet",
    removeMemberAlertTitle: "Supprimer",
    removeMemberAlertMessage: "Etes-vous certain de vouloir supprimer <b><user_name></b> du projet <b><project_name></b>. permanently ?",
    noProjects: "Aucun projet à afficher",
    owner: "Propriétaire",
    members: "Collègues",
    sharedWith: "Partagé avec",
    share: "Partager",
    noMilestones: "Jalon pas attribué",
    noMilestonesDesc1: "Les jalons sont utilisés pour suivre les progrès vers des objectifs ou des événements spécifiques.",
    noMilestonesDesc2: "La probabilité que les projets soient livrés avec succès est beaucoup plus élevée avec des jalons.",
    addMilestone: "Ajouter une milestone",
    writeAComment: "Ecrire un commentaire ici",
    addFollowUp: "Ajouter un sujet",
    noFollowUps: "Aucun sujet ajouté à ce projet"
  },
  followUps: {
    followUpInfos: "Détails du sujet",
    updated: "Sujet modifié",
    shareFollowUp: "Partager le sujet",
    createFollowUp: "Nouveau sujet",
    updateExistingFollowUp: "Modifier un sujet",
    updateFollowUp: "Modifier le sujet",
    searchFollowUp: "Chercher parmi les sujets",
    followUpsPerPage: "Sujets par page",
    created: "Le sujet a bien été créé",
    removeAlertTitle: "Supprimer un sujet",
    removeAlertMessage: "Êtes-vous sûr de vouloir supprimer définitivement le sujet <b><followup_name></b> ?",
    removeMemberAlertTitle: "Supprimer",
    removeMemberAlertMessage: "Êtes-vous sûr de vouloir définitivement supprimer <b><user_name></b> du sujet <followup_name></b>.?",
    show: "Aller à la page du sujet",
    followupMembers: "Sujet partagé avec",
    nextMeetings: "Réunions avec ce sujet",
    addOrRemoveUsers: "Partager le sujet avec",
    loadingProjects: "Projets en cours de chargement...",
    selectProject: "Sélectionner un projet",
    loadingFollowup: "Sujets en cours de chargement...",
    removeFromProject: "Supprimer du projet",
    removedFromProject: "Sujet supprimé du projet",
    followupMember: "Collègue",
    notVisible: "Vous n'avez pas les permissions pour accéder à cette resource.",
    askForInvitation: "Demandez à vos collègues de vous donner accès et rechargez la page",
    topicEmpty: "Le sujet est vide",
    followUpEmpty: "Le sujet est vide",
    noFollowUps: "Vous n'avez pas de sujets.",
    followUpsDescription1: "Les sujets que vous créez peuvent ensuite être ajoutés à des réunions ou des projets.",
    followUpsDescription2: "Dans un sujet, vous pourrez ajouter des notes, décisions, fichiers et tâches et les partager.",
    followUpStatus: "Statuts",
    createANewFollowUp: "Créer un nouveau sujet",
    selectExistingFollowUp: "Sélectionner un sujet existant",
    status: {
      done: "Fait",
      pending: "En attente",
      progress: "En cours",
      late: "En retard",
      stuck: "Bloqué",
      review: "En révision",
      canceled: "Annulé"
    },
    startDate: "Début",
    endDate: "Echéance",
    tasks: "Tâches",
    members: "Collègues",
    name: "Nom",
    filters: {
      allSubjects: "Tout les sujets",
      mySubjects: "Mes sujets",
      sharedWithMe: "Partagés avec moi",
      favorite: "Favoris"
    },
    addTopicsToMeeting: "Ajouter des sujets",
    addTopicToMeeting: "Ajouter à la réunion",
    followUpName: "Chercher un sujet existant",
    addedToMeeting: "Sujet ajouté à la réunion",
    followUpVisibleMsgAddTopic: "Tous les sujets ajoutés à la réunion seront automatiquement partagés avec tous les participants",
    followUpVisibleMsgAddMember: "Tous les sujets ajoutés à la réunion sont automatiquement partagés avec tous les participants",
    recentlyUsed: "Utilisés récemment",
    newFollowup: "Nouveau sujet",
    newFollowupTitle: "Titre du nouveau sujet",
    addToProject: "Ajouter le sujet à un projet ?",
    removeFromMeeting: "Supprimer de la réunion",
    noMeetingsPlanned: "Sujet ajouté à aucune réunion"
  },
  meetings: {
    status: {
      pending: "En attente",
      canceled: "Annulée",
      started: "En direct",
      paused: "En pause",
      finished: "Terminée",
      archived: "Archivée"
    },
    sidebar: {
      agendaLabel: "Ordre du jour",
      agendaTooltip: "Modifier les sujets et durées",
      participantsLabel: "Participants",
      participantsTooltip: "Ajouter ou supprimer des participants",
      objectivesLabel: "Objectifs",
      objectivesTooltip: "Définir des objectifs à la réunion",
      detailsLabel: "Détails",
      detailsTooltip: "Modifier les titre, description et lieu de la réunion",
      minuteLabel: "Compte rendu",
      minuteTooltip: "Configurer et partager le compte rendu"
    },
    addATitle: "Ajoutez un titre",
    recurringMeeting: "Réunion récurrente",
    meetingObjectivesCompleted: "Objectif(s) de la réunion remplis",
    noDuration: "Pas de durée",
    meetingFinished: "Réunion terminée !",
    increaseMeetingDurationTo: "Augmenter la durée de la réunion de <minutes> minutes",
    shrinkAllTopicDurations: "Changer la durée des sujets pour tenir dans <minutes> minutes",
    meetingEndTimeConflict: " La durée totale des sujets est supérieure que celle de la réunion !",
    meetingEndTimeConflictDescription: " La durée totale des sujets est supérieure que celle de la réunion !",
    solveTheConflict: "Résoudre le problème",
    openMeetingAgenda: "Ouvrir l'ordre du jour",
    meetingDurationUpdated: "La durée de la réunion est fixée à <minutes> minutes",
    meetingDuration: "Durée de la réunion",
    topicsTotalDuration: "Durée total des sujets",
    meetingEvents: "Occurrences de la réunion",
    autoStart: "Démarrer automatiquement la réunion",
    displayOlders: "Charger les réunions précédentes",
    startNow: "Démarrer le déroulé du temps",
    meetingStartIn: "La réunion doit commencer dans",
    meetingShouldHaveStartedIn: "La réunion devait commencer",
    startToPrepare: "Préparez la réunion en ajoutant des sujets ou en appliquant un modèle.",
    creatingMeeting: "Création de la réunion",
    timedTopics: "Sujets avec durée",
    timedTopicsDescription: "Vous devez indiquer une durée pour chaque sujet",
    setTopicAsDone: "Marquer le sujet comme traité",
    meetingsEmpty: "Vous n'avez pas de réunion préparée avec GetCollective",
    fromFollowup: "Choisir un sujet",
    newTopic: "Ajouter un sujet",
    noTopics: "Aucun sujet ajouté",
    addAnotherTopic: "ajouter un autre sujet",
    addFirstTopic: "Ajouter des sujets",
    inviteParticipants: "Inviter des participants",
    meetingIsLive: "En direct",
    saveTopic: "Ajouter",
    meetingAgenda: "Sujets et durées",
    meetingAgendaDescription: "Ajouter des sujets ou appliquer un modèle.",
    addATopic: "Ajouter un sujet",
    addATopicOrFollowup: "Ajouter des sujets",
    topic: "Sujet",
    shareMeetingMinute: "Envoyer un compte rendu",
    sharedByEmail: "Envoyé par email",
    minuteNotSharedYet: "Pas encore partagé",
    meetingSettings: "Paramètres de la réunion",
    nextScheduledMeeting: "Prochaine réunion dans",
    meetingForm: "Nouvelle réunion",
    removeAlertTitle: "Supprimer la réunion",
    removeAlertMessage: "Êtes-vous sûr de vouloir supprimer la réunion <b><meeting_title></b> pour toujours ?",
    removeSuccessNotification: "La réunion a bien été supprimée.",
    topicRemoveAlertTitle: "Supprimer un sujet",
    topicRemoveAlertMessage: "Êtes-vous sûr de vouloir supprimer le sujet ainsi que toutes ses tâches / notes / décisions pour toujours ?",
    newMeeting: "Nouvelle réunion",
    editMeetingInfos: "Modifier les détails de la réunion",
    meetingTitle: "Titre de la réunion",
    editMeeting: "Modifier la réunion",
    createMeeting: "Créer la réunion",
    updateMeeting: "Modifier la réunion",
    createRecurrentMeeting: "Créer la réunion recurrente",
    updateRecurrentMeeting: "Modifier la réunion recurrente",
    loadingMeeting: "Réunion en cours de chargement",
    details: "Détails",
    history: "Historique",
    assets: "Fichiers",
    addLink: "Ajouter un lien vers un fichier cloud",
    addFile: "Charger un fichier",
    startTime: "Heure de début",
    startDate: "Jour de début",
    endTime: "Heure de fin",
    topicUpdate: "Modifier le sujet",
    followupUpdate: "Modifier le sujet",
    udpate: "Modifier",
    maxDuration: "Durée maximum",
    maxDurationOptional: "Durée maximum (optionnel)",
    durationMinutesOptional: "Durée (optionnel)",
    inMinutes: "min",
    durationMinutes: "Durée (en minutes)",
    youNeedTimedTopics: "Sujets avec durée désactivés",
    youCantEditFollowupName: "Le tire du sujet ne peut pas être modifié",
    youCantEditFollowupDescription: "La description du sujet ne peut pas être modifiée",
    location: "Lieu",
    locationNotDefined: "Pas de lieu de la réunion",
    locationDesc: "Lieu de la réunion",
    description: "Description",
    descriptionDesc: "Description de la réunion",
    descriptionNotDefined: "Pas de description",
    topicDescriptionNotDefined: "Pas de description du sujet",
    objectives: "Objectifs",
    defineMeetingObjectives: "Définir des objectifs",
    objectivesNotDefined: "Pas d'objectif",
    objective: "Objectif",
    createObjective: "Ajouter un objectif",
    deletingMeeting: "Suppression de la réunion",
    listView: "Liste des réunions",
    calendarView: "Calendrier",
    participants: "Participants",
    meetingInfos: "Détails de la réunion",
    restartMeeting: "Redémarrer la réunion",
    addTopic: "Ajouter un sujet",
    minutes: "min",
    uploadRequierements: "La taille doit être inférieure à 20 Mo",
    displayOnlyCurrentTopic: "Uniquement de la réunion actuelle",
    historyHidden: "Les informations des autres réunions sont masquées",
    displayHistoryData: "Afficher les informations des autres réunions",
    currentMeeting: "Réunion en cours",
    currentTopic: "Sujet en cours",
    nextMeeting: "Réunion suivante",
    topics: "Sujets",
    saveMeetingSettings: "Modifier les paramètres de la réunion",
    removeUser: "Supprimer ce participant",
    invitedToMeeting: "Invité à la réunion",
    editDone: "Fermer la modification",
    leave: "Quitter la réunion",
    leaveAlertTitle: "Vous quittez cette réunion ?",
    leaveAlertMessage: "Êtes-vous sûr de vouloir quitter cette réunion ? ",
    fetchFailed: "La réunion n'existe pas ou bien vous n'y avez pas accès",
    returnToMeetingList: "Retourner à mes réunions",
    meeting: "Réunion",
    topicSpeaker: "Intervenant",
    editObjectives: "Modifier les objectifs",
    meetingObjectives: "Objectifs",
    objectivesHeadline: "Définissez des objectifs",
    objectivesHeadline2: "Ajoutez des objectifs et marquez-les comme terminés à la fin de la réunion",
    fromOrganisation: "collègues de <organisation>",
    externalCollaborators: "Collaborateurs externes",
    exitMeeting: "Fermer le meeting",
    draftNotEmty: "Vous avez des notes non publiées. <b>Êtes-vous sûr de vouloir quitter ?</b><br>Les notes non publiées seront supprimées.",
    addAllMeetingParticipants: "Ajouter tous les participants de la réunion",
    reload: "Actualiser",
    unamedMeeting: "Réunion sans titre",
    startMeeting: "Lancer le déroulé du temps",
    goToNextTopic: "Sujet suivant",
    endMeeting: "Terminer la réunion",
    templateLoading: "Appliquer un modèle",
    endDate: "Date de fin",
    prepareMeeting: "Préparation de la réunion",
    updateRecurrence: "Modifier l'événement périodique",
    updateRecurrenceOnlyOne: "Ce meeting",
    updateRecurrenceOneAndNext: "Ce meeting et tous les suivants",
    updateRecurrenceOnlyAll: "Tous les meetings",
    updated: 'Meeting mit à jour.',
    deleteRecurrence: "Supprimer l'événement périodique",
    deleteRecurrenceOnlyOne: "Supprimer cette réunion seulement",
    deleteRecurrenceOneAndNext: "Supprimer cette réunion et toute les suivantes",
    deleteRecurrenceOnlyAll: "Supprimer toute les réunions",
    deletedRecurrentMeeting: 'Réunion(s) supprimée(s).'
  },
  feedItems: {
    task: "Tâche",
    note: "Note",
    decision: "Décision",
    file: "Fichier",
    newTask: "+ tâche",
    newNote: "+ note",
    newDecision: "+ décision",
    newFile: "+ fichier",
    removeAlertTitle: "Supprimer une <feed_item_type>",
    removeAlertMessage: "Êtes-vous sûr de vouloir supprimer la <b><feed_item_type></b> définitivement ?",
    taskMember: "Responsable de la tâche",
    pinnedFiles: "Fichier épinglé",
    pinTheFile: "Épingler le fichier",
    unpinTheFile: "Dépingler le fichier",
    attach: "Joindre un fichier",
    attachments: "Fichiers joints"
  },
  feedItemsFilter: {
    all: "Tout",
    notes: "Notes",
    decisions: "Décisions",
    tasks: "Tâches",
    files: "Fichiers",
    urls: "Liens",
    filter: "Filtrer:",
    noItems: "Pas d'information publiée",
    noItemsFiltered: "Aucune information ne correspond à la récherche",
    loadOld: "Charger les réunions précédentes",
    file: "Fichier"
  },
  textEditor: {
    editItem: "Modifier",
    editing: "Modification",
    bold: "Gras",
    italic: "Italique",
    underline: "Souligner",
    bulletedList: "Liste à puces",
    numberedList: "Liste numérotée",
    title: "Titre",
    highlight: "Surligner",
    table: "Insérer un tableau",
    deleteTable: "Supprimer le tableau",
    addColumnBefore: "Ajouter une colonne avant",
    addColumnAfter: "Ajouter une colonne après",
    deleteColumn: "Supprimer la colonne",
    addRowBefore: "Ajouter une ligne avant",
    addRowAfter: "Ajouter une ligne après",
    deleteRow: "Supprimer la lligne",
    toggleCellMerge: "Fusionner les cellules",
    publishNote: "Publier la note",
    publishDecision: "Publier la décision",
    publishTask: "Publier la tâche",
    publishFile: "Publier le fichier",
    insertLink: "Insérer un lien",
    draft: "Votre bloc-notes",
    addNote: "Ajouter une note",
    addDecision: "Ajouter une décision",
    addTask: "Ajouter une tâche",
    addFile: "Ajouter un fichier",
    addNoteBelow: "Ajouter une note en dessous",
    addDecisionBelow: "Ajouter une décision en dessous",
    addTaskBelow: "Ajouter une tâche en dessous",
    addFileBelow: "Ajouter un fichier en dessous",
    writeYourNoteHere: "Écrire une note ici...",
    writeYourDecisionHere: "Écrire une décision ici...",
    writeYourTaskHere: "Écrire une tâche ici...",
    setAsNote: "Définir comme <b>Note</b>",
    setAsDecision: "Définir comme <b>Décision</b>",
    draftEmpty: "Votre bloc-notes est vide",
    startByAdding: 'Cliquez sur "Nouvelle note" ci-dessus pour commencer à ajouter des informations, des décisions, des tâches et des fichiers',
    publishAll: "Tout publier",
    focusEditor: "Focus sur votre bloc-notes",
    sideBySide: "Côte à côte",
    minimize: "Minimiser",
    assignTask: "Assigner la tâche",
    dueDate: "Échéance",
    assignedToTask: "Assigné à la tâche",
    taskAssignedTo: "Tâche assignée à:",
    convertToCard: "Convertir en note",
    splitCell: "Diviser la cellule",
    addDecisions: "Ajouter une décision",
    addTasks: "Ajouter une liste de tâches",
    insertUrl: "Insérer un lien",
    url: "Adresse Web",
    urlText: "Texte"
  },
  templates: {
    createSuccess: "Le modèle a bien été créé",
    createTemplate: "Créer un modèle",
    empty: "Vous n'avez pas de modèle.",
    featureNotAvailable: " ",
    fromTemplate: "À partir d'un modèle",
    loadATemplate: "Appliquer un modèle",
    loadTemplate: "Appliquer le modèle",
    removeAlertMessage: "Êtes-vous sûr de vouloir supprimer le modèle <b><template_name></b> définitivement ?",
    removeAlertTitle: "Supprimer un modèle",
    searchTemplates: "Chercher parmi les modèles",
    templates: "Modèles",
    template: "Modèle",
    templateCreated: "Modèle créé",
    templateName: "Nom du modèle",
    templateSuffix: "(modèle)",
    saveAsTemplate: "Sauvegarder en tant que modèle",
    useTemplate: "Utiliser le modèle",
    selectTemplate: "Sélectionner un modèle",
    creatingTemplate: "création d'un modèle à partir de la réunion",
    loadingTemplates: "Chargement des modèles...",
    templateDescription: "Vous pouvez sauvegarder cette réunion en tant que modèle.",
    templateFeatureDesc: "Vous n'avez pas de modèle de réunion.",
    templateFeatureDesc2: "Un modèle est une liste de sujets prédéfinis que vous pouvez ajouter à une réunion.",
    templateFeatureDesc3: "Un modèle est une liste de sujets prédéfinis que vous pouvez ajouter à une réunion."
  },
  groups: {
    visibility: "Visibilité:",
    createGroup: "Nouvelle équipe",
    addMembers: "Ajouter des membres",
    privateGroup: "Privé",
    publicDescription: "Tout le monde dans votre organisation peut voir cette équipe",
    privateDescription: "Seuls les membres de l'équipe et l'administrateur de votre organisation peuvent voir cette équipe",
    publicGroup: "Publique",
    groups: "Équipes",
    groupDesc: "Les équipes permettent de facilement inviter et partager des éléments avec des groupes de personnes",
    member: "membre",
    members: "membres",
    name: "Nom",
    groupMembers: "Membres de l'équipe",
    addUser: "Ajouter une équipe",
    removeUser: "Supprimer de l'équipe",
    renameGroup: "Renommer l'équipe",
    deleteGroup: "Supprimer l'équipe",
    groupName: "Nom de l'équipe",
    empty: "L'équipe n'a pas de membres",
    deleteGroupAlertTitle: "Supprimer l'équipe de l'organisation",
    deleteGroupAlertMessage: "Êtes-vous sûr de vouloir définitivement supprimer l'équipe <b><groupname></b> de votre organisation ?",
    deleteGroupUserAlertTitle: "Supprimer le membre de l'équipe",
    deleteGroupUserAlertMessage: "Êtes-vous sûr de vouloir supprimer <b><username></b> de <b><groupname></b> ?",
    memberOfTheGroup: "Membre de l'équipe"
  },
  inputRules: {
    cantBeNegative: "Ne peut pas être une valeur négative",
    cantBeNull: "Ne peut pas être nul",
    maxTextLength: "<length> caractères max"
  },
  integration: {
    outlookExtension: "Complément Microsoft Outlook",
    getItNow: "Ajouter maintenant"
  },
  fileViewer: {
    fileViewer: "Visionneuse de fichier",
    fullscreen: "Plein écran",
    standardView: "Sortir du plein écran",
    download: "Télécharger",
    closeFile: "Fermer le fichier",
    selectFile: "Sélectionner un fichier"
  },
  meetingMinutes: {
    close: "Retourner à la réunion",
    share: "Partager",
    shareMeetingMinute: "Partager le compte rendu",
    downloadPdf: "Télécharger en PDF",
    minuteSettings: "Paramètres du compte rendu",
    meetingMinutePreview: "Prévisualisation du compte rendu",
    companyLogo: "Logo du compte rendu",
    importLogo: "Importer un logo",
    summary: "Résumé des tâches et décisions",
    decisions: "Décisions",
    tasks: "Tâches",
    meetingDetails: "Détails de la réunion",
    description: "Description",
    location: "Lieu",
    objectives: "Objectifs",
    participants: "Participants",
    list: "Afficher les participants",
    attendees: "Relevé de présence",
    sharingMinute: "Envoi du compte rendu...",
    youCanShareSeveral: "Vous pouvez ajouter plusieurs emails à la suite.",
    hasBeenShared: "Le compte rendu a été partagé."
  },
  email: {
    to: "Ajouter des utilisateurs ou des adresses email:",
    selectRecipients: "Ajouter des utilisateurs ou des adresses email:",
    checkForErrors: "Veuillez vérifier que les adresses sont correctes."
  },
  tasks: {
    noTasks: "Aucune tâche à afficher",
    done: "Fait",
    search: "Chercher une tâche",
    allTask: "Toutes les tâches",
    completedTasks: "Tâches réalisées",
    incompleteTasks: "Tâches non réalisées",
    myTasks: "Mes tâches",
    task: "Tâche",
    tasks: "Tâches",
    dueDate: "À faire",
    all: "Toutes",
    priority: "Priorité",
    context: "De la note",
    assignedTo: "Assigné à",
    everyone: "Tout le monde",
    project: "Projet",
    deleteTask: "Supprimer la tâche",
    priorities: {
      low: "Faible",
      medium: "Moyenne",
      high: "Haute",
      critical: "Critique"
    },
    closeTaskDetails: "Fermer les détails",
    markComplete: "Marquer comme réalisée",
    completed: "Réalisée",
    goToTopic: "Aller au sujet",
    taskBelongsTo: "Cette tâches est de la note suivante:",
    removeAlertTitle: "Supprimer la tâche",
    removeAlertMessage: "Etes-vous certain de vouloir supprimer la tâche <b><task_content></b> définitivement ?"
  },
  calendar: {
    month_0: "Janvier",
    month_1: "Février",
    month_2: "Mars",
    month_3: "Avril",
    month_4: "Mai",
    month_5: "Juin",
    month_6: "Juillet",
    month_7: "Août",
    month_8: "Septembre",
    month_9: "Octobre",
    month_10: "Novembre",
    month_11: "Decembre",
    day: "Jour",
    days: "Jours",
    days4: "4 jours",
    week: "Semaine",
    weeks: "Semaines",
    month: "Mois",
    months: "Mois",
    year: "Année",
    years: "Années",
    nextDay: "Jour suivant",
    previousDay: "Jour précédent",
    nextWeek: "Semaine suivante",
    previousWeek: "Semaine précédente",
    nextMonth: "Mois suivant",
    previousMonth: "Mois précédent",
    nextPeriod: "Période suivante",
    previousPeriod: "Période précédente",
    recurring: "Récurrent",
    recurrence: {
      repeat: "Périodicité",
      doesNotRepeat: "Une seule fois",
      daily: "Tout les jours",
      weeklyOn: "Toute les semaines le <day>",
      monthlyOn: "Tout les mois le <day>",
      monthlyOnFirst: "Tout les mois le premier <day>",
      monthlyOnSecond: "Tout les mois le deuxième <day>",
      monthlyOnThird: "Tout les mois le troisième <day>",
      monthlyOnFourth: "Tout les mois le quatrième <day>",
      monthlyOnLast: "Tout les mois le dernier <day>",
      annuallyOn: "Tout les ans le <day-number> <month>",
      everyWeekDay: "Tout les jours de la semaine (du lundi au vendredi)",
      custom: "Personnaliser..."
    },
    customRecurrence: "Récurrence personnalisée",
    repeatEvery: "Répéter tou(te)s les",
    repeatOn: "Répéter le",
    ends: "Se termine",
    occurences: "occurences",
    occurence: "occurence",
    weekdayShort: ["M", "T", "W", "T", "F", "S", "S"],
    endsNever: "Jamais",
    endsOn: "Le",
    endsAfter: "Après"
  }
};
