
const state = {
  topicCards: {},
  followUpCards: {},
  cards: {},
  cardRefresh: 0,
  editedCardId: null,
  editedCardTopicId: null,
  editedCardFollowUpId: null,
  editedTaskId: null
}

const getters = {
  cardRefresh: state => state.cardRefresh,
  cards: state => state.cards,
  topicCards: state => topic_id => {
    state.cardRefresh;
    if (state.topicCards[topic_id])
      return state.topicCards[topic_id].map(card_id => state.cards[card_id])
    return [];
  },
  followUpCards: state => follow_up_id => {
    state.cardRefresh;
    if (state.followUpCards[follow_up_id])
      return state.followUpCards[follow_up_id].map(card_id => state.cards[card_id])
    return [];
  },
  editedCardId: state => state.editedCardId,
  editedCardTopicId: state => state.editedCardTopicId,
  editedCardFollowUpId: state => state.editedCardFollowUpId,
  editedCard: state => {
    state.cardRefresh;
    if (!state.editedCardId) return null;
    return state.cards[state.editedCardId];
  },
  editedTaskId: state => state.editedTaskId,
  editedTask: state => {
    state.cardRefresh;
    if (!state.editedCardId) return null;
    let card = state.cards[state.editedCardId];
    if (!card) return null;
    return state.cards[state.editedCardId].feed_items.find(e => e.id === state.editedTaskId);
  }
}

function escapeHtml(content) {
  return content
    .substring(0, 100)
    .replace(/<\/{0,1}[a-z\ ]+\/{0,1}>/gi, "");
}

function getTitle(card) {
  // Prio order: Title -> Decisions -> Content -> Task -> File
  // Title
  if (card.title) return card.title;

  // Decisions
  if (card.decisions.length)
    return escapeHtml(card.decisions[0].content);

  // Content
  if (card.content)
    return escapeHtml(card.content);

  // Tasks
  if (card.tasks.length)
    return escapeHtml(card.tasks[0].content);

  // File
  if (card.files.length)
    return card.files[0].url
      ? decodeURI(card.files[0].url.substring(url.lastIndexOf("/") + 1))
      : "File";

  // Urls
  if (card.urls.length) return card.urls[0].url ? card.urls[0].url : "Url";

  return "Undefined content";
}

function enrichCard(card) {
  card.decisions = card.feed_items.filter(e => e.feed_item_type === "decision");
  card.tasks = card.feed_items.filter(e => e.feed_item_type === "task");
  card.tasks_done = card.tasks.filter(t => t.done).length;
  card.files = card.feed_items.filter(e => e.feed_item_type === "file");
  card.urls = card.feed_items.filter(e => e.feed_item_type === "url");
  card.tags = card.card_tags.map(e => e.tag);

  card.has_decisions = card.decisions.length > 0;
  card.has_tasks = card.tasks.length > 0;
  card.has_files = card.files.length > 0;
  card.has_urls = card.urls.length > 0;
  // if (!card.title) card._title = getTitle(card);

  return card;
}

const actions = {
  addFollowUpCards({ commit }, { follow_up_id, cards }) {
    commit("addFollowUpCards", { follow_up_id, cards });
  },
  cleanCards({ commit }) {
    commit("cleanCards");
  },
  hydrateCard({ commit }, card) {
    commit("hydrateCard", card);
  },
  destroyCard({ commit }, card) {
    commit("destroyCard", card);
  },
  updateDecisionsPos({ commit }, card) {
    commit("updateDecisionsPos", card);
  },
  updateTasksPos({ commit }, card) {
    commit("updateTasksPos", card);
  },
  setEditedCardId({ commit }, card_id) {
    commit("setEditedCardId", card_id);
  },
  setEditedCardTopicId({ commit }, topic_id) {
    commit("setEditedCardTopicId", topic_id);
  },
  setEditedCardFollowUpId({ commit }, follow_up_id) {
    commit("setEditedCardFollowUpId", follow_up_id);
  },
  setEditedTaskId({ commit }, task_id) {
    commit("setEditedTaskId", task_id);
  },
}

const mutations = {
  addTopicCards(state, { topic_id, cards }) {
    state.topicCards[topic_id] = cards.map(e => e.id);
    cards.forEach(card => state.cards[card.id] = enrichCard(card))
  },
  addFollowUpCards(state, { follow_up_id, cards }) {
    state.followUpCards[follow_up_id] = cards.map(e => e.id);
    cards.forEach(card => state.cards[card.id] = enrichCard(card))
    state.cardRefresh++;
  },
  cleanCards(state) {
    state.topicCards = {};
    state.followUpCards = {};
    state.cards = {};
  },
  hydrateCard(state, card) {
    state.cards[card.id] = enrichCard(card);
    if (card.follow_up_id) {
      if (!state.followUpCards[card.follow_up_id])
        state.followUpCards[card.follow_up_id] = [];
      if (!state.followUpCards[card.follow_up_id].includes(card.id))
        state.followUpCards[card.follow_up_id].push(card.id)
    } else {
      if (!state.topicCards[card.topic_id])
        state.topicCards[card.topic_id] = [];
      if (!state.topicCards[card.topic_id].includes(card.id))
        state.topicCards[card.topic_id].push(card.id)
    }
    state.cardRefresh++;
  },
  destroyCard(state, card) {
    if (state.followUpCards[card.follow_up_id])
      state.followUpCards[card.follow_up_id] = state.followUpCards[card.follow_up_id].filter(e => e !== card.id)

    if (state.topicCards[card.topic_id])
      state.topicCards[card.topic_id] = state.topicCards[card.topic_id].filter(e => e !== card.id)

    if (state.editedCardId === card.id)
      state.editedCardId = null;

    delete state.cards[card.id];
    state.cardRefresh++;
  },
  updateDecisionsPos(state, card) {
    state.cards[card.id].decisions = card.decisions;
    state.cardRefresh++;
  },
  updateTasksPos(state, card) {
    state.cards[card.id].tasks = card.tasks;
    state.cardRefresh++;
  },
  setEditedCardId(state, card_id) {
    state.editedCardId = card_id;
  },
  setEditedCardTopicId(state, topic_id) {
    state.editedCardTopicId = topic_id;
  },
  setEditedCardFollowUpId(state, follow_up_id) {
    state.editedCardFollowUpId = follow_up_id;
  },
  setEditedTaskId(state, task_id) {
    state.editedTaskId = task_id;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
