<template>
  <div>
    <v-overlay :z-index="100000" :value="online === false">
      <v-overlay :value="true">
        <v-container fill-height fluid>
          <v-row class="text-center">
            <v-col cols="12">
              <v-icon size="5em" color="white">mdi-wifi-off</v-icon>
            </v-col>
            <v-col cols="12">
              <h4>{{ $t("misc.failedToConnect") }}</h4>

              <span class="caption">{{ counterMessage }}</span>
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="attemptToConnect()"
                color="error"
                :loading="counter <= 0"
              >
                {{ $t("misc.retryNow") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-overlay>

    <!-- <v-snackbar :value="online === false" color="error" :timeout="-1" top>
      <div class="mr-3">
        <v-icon small color="white">mdi-wifi-off</v-icon>
      </div>
      <span>You are currently offline. {{ counter | counterMessage }}</span>
      <v-btn @click="checkConnection" color="red" v-show="counter != 0">
        Retry Now
      </v-btn>
    </v-snackbar> -->

    <v-snackbar
      v-model="display"
      :color="colorNotif(notification)"
      :timeout="notification.timeout"
      right
      text
      tile
      transition="slide-x-reverse-transition"
    >
      <v-icon :color="colorNotif(notification)" class="pr-2">{{ notification.icon }}</v-icon>
      {{ notification.text }}

      <!-- <template v-slot:action="{ attrs }">
        <v-btn icon right text @click="display = false" v-bind="attrs"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template> -->
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setInterval, clearInterval } from "timers";

export default {
  data() {
    return {
      display: false,
      counter: -1,
      nbConnectionRetry: 0,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["notification", "online"]),
    counterMessage() {
      // if (this.counter < 0) return "";
      if (this.counter < 1) return this.$t("misc.retrying");
      else
        return this.$t("misc.attemptToConnectIn").replace(
          "<counter>",
          this.counter
        );
    },
  },
  methods: {
    ...mapActions(["checkConnection"]),
    colorNotif(notif) {
      return notif.color;
    },
    attemptToConnect() {
      this.counter = 0;
      this.checkConnection();
    },
    handleNotification() {
      if (this.display) {
        this.display = false;
        this.$nextTick(() => {
          this.display = true;
        });
      } else {
        this.display = true;
      }
    },
    runReconnectLoop() {
      if (!this.online && this.nbConnectionRetry < 8) {
        this.counter = 2 ** this.nbConnectionRetry;
        this.interval = setInterval(
          (self) => {
            self.counter--;
            if (self.counter <= 0) {
              clearInterval(self.interval);
              self.nbConnectionRetry++;
              setTimeout(() => {
                self.runReconnectLoop();
              }, 2000);
              this.checkConnection();
            }
          },
          1000,
          this
        );
      }
    },
    clearReconnectLoop() {
      this.counter = -1;
      clearInterval(this.interval);
      this.nbConnectionRetry = 0;
    },
  },
  watch: {
    notification() {
      this.handleNotification();
    },
    online(val) {
      if (val === false && this.counter == -1) {
        this.runReconnectLoop();
      } else {
        this.clearReconnectLoop();
      }
    },
  },
};
</script>
